<template>
  <section
    class="preview-template-view"
    v-loading="loading"
    :element-loading-text="loadingText"
  >
    <div v-if="hasRepeatableTemplate">
      <el-scrollbar :wrap-style="getRepeatableContainerStyle" v-if="showData">
        <div v-if="!entitiesData.length">
          <el-empty description="No data added"></el-empty>
        </div>
        <div v-for="(formData, i) in entitiesData" :key="i + 'repeatable_data'">
          <repeatableTemplatePreview
            v-if="!checkhideFields(field)"
            :templateData="currentStep.template"
            :isExecute="isEdit"
            :form="formData"
            :index="i + 1"
            v-on:removeRowData="removeRowData"
            @applyFormRules="rulesEventEmitter"
            :authenticatedUserInfoFromFormbuilder="
              authenticatedUserInfoFromFormbuilder
            "
          ></repeatableTemplatePreview>
        </div>
      </el-scrollbar>
      <div class="d-flex" v-if="!getIsView">
        <el-button
          class="mt-1 ml-1"
          @click="addNewRepeatableData"
          type="primary"
          plain
          icon="el-icon-plus"
          :disabled="getMaxNoOfRecords <= entitiesData.length"
          >Add Row</el-button
        >
        <el-button
          type="success"
          :disabled="getMinNoOfRecords > entitiesData.length"
          @click="saveTemplate(currentStepIndex - 1)"
          style="height: 40px"
          class="mt-1"
          :style="getBrandingStyles"
        >
          {{ this.currentStep.has_next ? "Next" : getSaveButtonLabel() }}
          <i
            v-if="this.currentStep.has_next"
            class="fa fa-chevron-right"
            aria-hidden="true"
          ></i>
          <i v-else class="fa fa-check" aria-hidden="true"></i>
        </el-button>
      </div>
    </div>
    <div>
      <el-row
        v-if="
          !formbuilderDetails ||
          !formbuilderDetails.customization ||
          !formbuilderDetails.customization.button_position ||
          (formbuilderDetails &&
            formbuilderDetails.customization &&
            formbuilderDetails.customization.button_position == 'TOP')
        "
        style="
          border-bottom: 1px solid #e6e6e6;
          display: flex;
          padding: 0px 15px;
        "
        :class="rowAlignment"
      >
        <div>
          <el-button
            class="float-right"
            type="success"
            @click="saveTemplate(currentStepIndex + 1)"
            :style="getBrandingStyles"
            v-if="!isUserApprovingForm && !isExecuteViewStep"
          >
            {{ this.currentStep.has_next ? "Next" : getSaveButtonLabel() }}
            <i
              v-if="this.currentStep.has_next"
              class="fa fa-chevron-right"
              aria-hidden="true"
            ></i>
            <i v-else class="fa fa-check" aria-hidden="true"></i>
          </el-button>
        </div>
      </el-row>
      <div>
        <div :style="getContainerStyle" v-if="showData">
          <div class="template-body-section">
            <div
              v-if="isAllQuestionsAnswered && interactiveQuestionsMode"
              class="text-center"
            >
              <span>You have completed all questions</span>
              <br />
              <span>
                <el-button
                  type="primary"
                  size="mini"
                  class="mt-1"
                  plain
                  @click="stayAndReviewPage"
                  >Stay & Review</el-button
                >
                <el-button
                  type="success"
                  size="mini"
                  class="mt-1"
                  @click="saveTemplate(currentStepIndex + 1)"
                  :style="getBrandingStyles"
                >
                  {{
                    this.currentStep.has_next ? "Next" : getSaveButtonLabel()
                  }}
                  <i
                    v-if="this.currentStep.has_next"
                    class="fa fa-chevron-right"
                    aria-hidden="true"
                  ></i>
                  <i v-else class="fa fa-check" aria-hidden="true"></i>
                </el-button>
              </span>
            </div>
            <el-form
              @submit.native.prevent
              v-else-if="interactiveQuestionsMode"
            >
              <preview-interactive-template-form
                v-if="!checkhideFields(field)"
                :templateData="templateData"
                :fieldsData="getFieldsData"
                :isExecute="isExecute"
                :form="form"
                :interactiveQuestionsModeType="interactiveQuestionsModeType"
                :dataId="entityDataId"
                :isFormFormbuilder="true"
                :resetQuestionireForm="resetQuestionireForm"
                :checkIsDisabled="checkIsDisabled(field)"
                @applyFormRules="rulesEventEmitter"
                @onFilledQuestion="updateInteractiveQuestionFillingStatus"
              ></preview-interactive-template-form>
            </el-form>

            <el-form v-else @submit.native.prevent>
              <div class="form-builder" ref="formBuilder" :style="getStylediv">
                <div
                  v-if="
                    fieldsData && fieldsData.length && !hasRepeatableTemplate
                  "
                  class="form-fields-holder"
                >
                  <div
                    v-for="(field, index) of getFieldsData"
                    :key="index"
                    :style="getExistedStyle(field) || getStyle(field, index)"
                  >
                    <HorizontalLineExecute
                      :data="field"
                      v-if="field.input_type === 'HORIZONTAL_LINE'"
                      :form="form"
                      :is-view="!isExecute"
                    ></HorizontalLineExecute>
                    <DateExecute
                      :data="field"
                      v-if="
                        field.input_type === 'DATE' && !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></DateExecute>
                    <DateTimeExecute
                      :data="field"
                      v-if="
                        field.input_type === 'DATE_TIME' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></DateTimeExecute>
                    <DateTimeRangeExecute
                      :data="field"
                      v-if="
                        field.input_type === 'DATE_TIME_RANGE' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></DateTimeRangeExecute>
                    <DateRangeExecute
                      :data="field"
                      v-if="
                        field.input_type === 'DATE_RANGE' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></DateRangeExecute>
                    <CheckBoxGroupExecute
                      :data="field"
                      v-if="
                        field.input_type === 'CHECKBOX_GROUP' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :hasLabel="hasLabel"
                      :is-view="!isExecute"
                      :fieldsData="getFieldsData"
                      :checkIsDisabled="checkIsDisabled(field)"
                      :highlight="getRequiredStatus(field)"
                      @applyFormRules="rulesEventEmitter"
                    ></CheckBoxGroupExecute>
                    <RadioGroupExecute
                      :data="field"
                      v-if="
                        field.input_type === 'RADIO_BUTTON_GROUP' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :hasLabel="hasLabel"
                      :is-view="!isExecute"
                      :fieldsData="fieldsData"
                      :checkIsDisabled="checkIsDisabled(field)"
                      :highlight="getRequiredStatus(field)"
                      @applyFormRules="rulesEventEmitter"
                    ></RadioGroupExecute>
                    <TimeExecute
                      :data="field"
                      v-if="
                        field.input_type === 'TIME' && !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></TimeExecute>
                    <TimeRangeExecute
                      :data="field"
                      v-if="
                        field.input_type === 'TIME_RANGE' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :hasLabel="hasLabel"
                      :is-view="!isExecute"
                      :checkIsDisabled="checkIsDisabled(field)"
                      :highlight="getRequiredStatus(field)"
                    ></TimeRangeExecute>
                    <FileExecute
                      :data="field"
                      v-if="field.input_type === 'FILE'"
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :checkIsDisabled="checkIsDisabled(field)"
                      :templatesData="existedTemplateDataValues"
                      :highlight="getRequiredStatus(field)"
                      :filesInfo="filesInfo"
                      @fileUploadData="handleFileUploadData"
                    ></FileExecute>
                    <MultiLineTextExecute
                      :data="field"
                      v-if="
                        (field.input_type === 'MULTI_LINE_TEXT' ||
                          field.input_type === 'RICH_TEXT') &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :isFromView="false"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></MultiLineTextExecute>

                    <!-- {{ field.input_type }}
                    {{ form }}-->

                    <SingleLineTextExecute
                      :data="field"
                      v-if="
                        (field.input_type === 'SINGLE_LINE_TEXT' ||
                          field.input_type === 'EMAIL' ||
                          field.input_type === 'PASSWORD' ||
                          field.input_type === 'MASKED' ||
                          field.input_type === 'HYPER_LINK') &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :highlight="getRequiredStatus(field)"
                      :hasLabel="hasLabel"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                    ></SingleLineTextExecute>
                    <MultiSelectExecute
                      :data="field"
                      v-if="
                        field.input_type === 'MULTI_SELECT' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></MultiSelectExecute>
                    <SelectExecute
                      :data="field"
                      v-if="
                        field.input_type === 'SELECT' && !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></SelectExecute>
                    <YesOrNoExecute
                      :data="field"
                      v-if="
                        field.input_type === 'YES_OR_NO' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></YesOrNoExecute>
                    <NumberExecute
                      :data="field"
                      v-if="
                        (field.input_type === 'NUMBER' ||
                          field.input_type === 'ZIP_CODE') &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></NumberExecute>
                    <PhoneCountryCodeExecute
                      :data="field"
                      v-if="field.input_type === 'PHONE_COUNTRY_CODE'"
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :primaryData="getNewEntityData"
                      :checkIsDisabled="checkIsDisabled(field)"
                      :highlight="getRequiredStatus(field)"
                    ></PhoneCountryCodeExecute>
                    <CurrencyExecute
                      :data="field"
                      v-if="
                        field.input_type === 'CURRENCY' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></CurrencyExecute>
                    <HeadingExecute
                      :data="field"
                      v-if="field.input_type === 'HEADING'"
                      :form="form"
                      :is-view="!isExecute"
                    ></HeadingExecute>
                    <CheckBoxExecute
                      :data="field"
                      v-if="
                        field.input_type === 'CHECKBOX' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :fieldsData="getFieldsData"
                      :singleCompanyTemplateCheckBoxArray="
                        singleCompanyTemplateCheckBoxArray
                      "
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></CheckBoxExecute>
                    <RadioExecute
                      :data="field"
                      v-if="
                        field.input_type === 'RADIO' && !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :fieldsData="getFieldsData"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></RadioExecute>
                    <ESignatureExecute
                      :data="field"
                      v-if="field.input_type === 'SIGNATURE'"
                      :is-view="!isExecute"
                      :form="form"
                      :hasLabel="hasLabel"
                      :highlight="getRequiredStatus(field)"
                    ></ESignatureExecute>
                    <GlobalVariableExecute
                      :data="field"
                      v-if="field.input_type === 'GLOBAL_VARIABLE'"
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :fieldsData="fieldsData"
                      :highlight="getRequiredStatus(field)"
                    ></GlobalVariableExecute>

                    <EntityVariableExecute
                      :data="field"
                      v-if="
                        field.input_type === 'ENTITY_VARIABLE' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :fieldsData="fieldsData"
                      @entityDataUpdated="setDataToEntityVariables"
                      @clearEntityFields="unsetEntityVariables"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></EntityVariableExecute>

                    <EntityExecute
                      :data="field"
                      v-if="
                        field.input_type === 'ENTITY' && !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :fieldsData="fieldsData"
                      :formbuilderDetails="formbuilderDetails"
                      @entityDataUpdated="setDataToEntityVariables"
                      @clearEntityFields="unsetEntityVariables"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                      :suggestedData="suggestedData"
                    ></EntityExecute>

                    <ParagraphExecute
                      :data="field"
                      v-if="field.input_type === 'PARAGRAPH'"
                      :form="form"
                      :is-view="!isExecute"
                      :highlight="getRequiredStatus(field)"
                    ></ParagraphExecute>
                    <SingleLineContentExecute
                      :data="field"
                      v-if="field.input_type === 'SINGLE_LINE_CONTENT'"
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :highlight="getRequiredStatus(field)"
                    ></SingleLineContentExecute>
                    <AuthorizedSignatureExecute
                      :data="field"
                      v-if="field.input_type === 'AUTHORIZED_SIGNATURE'"
                      :form="form"
                      :is-view="!isExecute"
                      :highlight="getRequiredStatus(field)"
                    ></AuthorizedSignatureExecute>
                    <!-- IMage html formula -->
                    <FormulaExecute
                      :data="field"
                      v-if="
                        field.input_type === 'FORMULA' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :workflowData="getUserFormbuilderData"
                      :entityDataId="entityDataId"
                      :hasLabel="hasLabel"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></FormulaExecute>
                    <ActionButtonExecute
                      :data="field"
                      v-if="
                        field.input_type === 'ACTION_BUTTON' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :workflowDataId="entityDataId"
                      :fieldsData="fieldsData"
                      @applyFormRules="rulesEventEmitter"
                      :checkIsDisabled="checkIsDisabled(field)"
                      :highlight="getRequiredStatus(field)"
                    ></ActionButtonExecute>

                    <ImageExecute
                      :data="field"
                      v-if="
                        field.input_type === 'IMAGE' ||
                        field.input_type === 'GIF'
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :highlight="getRequiredStatus(field)"
                    ></ImageExecute>
                    <VideoExecute
                      :data="field"
                      v-if="field.input_type === 'VIDEO'"
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :highlight="getRequiredStatus(field)"
                    ></VideoExecute>
                    <HtmlExecute
                      :data="field"
                      v-if="field.input_type === 'HTML_CONTENT'"
                      :form="form"
                      :is-view="!isExecute"
                      :highlight="getRequiredStatus(field)"
                    ></HtmlExecute>
                    <ListExecute
                      :data="field"
                      v-if="field.input_type === 'LIST'"
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :highlight="getRequiredStatus(field)"
                    ></ListExecute>
                    <DIVExecute
                      :data="field"
                      v-if="field.input_type === 'DIVISION'"
                      :form="form"
                      :highlight="getRequiredStatus(field)"
                    ></DIVExecute>
                    <AggregateFunctionExecute
                      :data="field"
                      v-if="field.input_type === 'AGGREGATE_FUNCTION'"
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :workflowDataId="entityDataId"
                      :fieldsData="fieldsData"
                      :highlight="getRequiredStatus(field)"
                    ></AggregateFunctionExecute>
                    <QuestionExecute
                      :data="field"
                      v-if="
                        field.input_type === 'QUESTION' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      @applyFormRules="rulesEventEmitter"
                      :checkIsDisabled="checkIsDisabled(field)"
                      :highlight="getRequiredStatus(field)"
                    ></QuestionExecute>

                    <AutoIncrementExecute
                      :data="field"
                      v-if="field.input_type === 'AUTO_INCREMENT_NUMBER'"
                      :form="form"
                      :is-view="!isExecute"
                      :entityDataId="entityDataId"
                      :entityId="getEntityId"
                      :hasLabel="hasLabel"
                      :highlight="getRequiredStatus(field)"
                    ></AutoIncrementExecute>
                    <WeekDaysExecute
                      :data="field"
                      v-if="
                        field.input_type == 'WEEKDAYS' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :hasLabel="hasLabel"
                      :is-view="!isExecute"
                      @applyFormRules="rulesEventEmitter"
                      :checkIsDisabled="checkIsDisabled(field)"
                      :highlight="getRequiredStatus(field)"
                    ></WeekDaysExecute>
                    <StarRatingExecute
                      :data="field"
                      v-if="
                        field.input_type == 'STAR_RATING' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :hasLabel="hasLabel"
                      :is-view="!isExecute"
                      @applyFormRules="rulesEventEmitter"
                      :checkIsDisabled="checkIsDisabled(field)"
                      :highlight="getRequiredStatus(field)"
                    ></StarRatingExecute>

                    <DataTableExecute
                      :class="'dataTable' + index"
                      ref="dataTable"
                      :data="field"
                      v-if="field.input_type === 'DATA_TABLE'"
                      :form="form"
                      :is-view="!isExecute"
                      :fieldsData="fieldsData"
                      @updateTableData="updateTableFieldData"
                      @onNewRowAdded="updateTopForBelowElements"
                      :highlight="getRequiredStatus(field)"
                      :suggestedData="suggestedData"
                    ></DataTableExecute>
                    <ConcatenateExecute
                      :data="field"
                      v-if="
                        field.input_type === 'CONCATENATE' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></ConcatenateExecute>
                    <RandomTextExecute
                      :data="field"
                      v-if="
                        field.input_type === 'RANDOM_TEXT' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></RandomTextExecute>
                    <AudioExecute
                      :data="field"
                      v-if="
                        field.input_type == 'AUDIO' && !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :colorFields="colorFields"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></AudioExecute>
                    <CaptchaExecute
                      :data="field"
                      v-if="
                        field.input_type == 'CAPTCHA' && !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :colorFields="colorFields"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></CaptchaExecute>
                    <QRExecute
                      :data="field"
                      v-if="field.input_type == 'QR' && !checkhideFields(field)"
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :colorFields="colorFields"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></QRExecute>
                    <QRReaderExecute
                      :data="field"
                      v-if="
                        field.input_type == 'QR_READER' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :hasLabel="hasLabel"
                      :colorFields="colorFields"
                      :checkIsDisabled="checkIsDisabled(field)"
                      @applyFormRules="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                    ></QRReaderExecute>
                    <EntityTableExecute
                      :class="'dataTable' + index"
                      ref="dataTable"
                      :data="field"
                      v-if="
                        field.input_type === 'ENTITY_TABLE' &&
                        !checkhideFields(field)
                      "
                      :form="form"
                      :is-view="!isExecute"
                      :fieldsData="fieldsData"
                      :entityDataId="entityDataId"
                      :hiddenColumns="hideFields"
                      :entityTableDetails="getEntityTableDetails(field, form)"
                      :checkAllFieldsDisabled="checkIsDisabled(field)"
                      @updateTableData="updateTableFieldData"
                      @onNewRowAdded="updateTopForBelowElements"
                      @onDataAdded="rulesEventEmitter"
                      :highlight="getRequiredStatus(field)"
                      :suggestedData="suggestedData"
                    ></EntityTableExecute>
                  </div>
                </div>
              </div>
            </el-form>
          </div>
        </div>
        <div
          class="float-right"
          style="margin-right: 20px; margin-bottom: 10px"
          v-if="
            isUserApprovingForm &&
            (checkFormbuilderStatus('APPROVED') ||
              checkFormbuilderStatus('REJECTED'))
          "
        >
          <div v-if="checkFormbuilderStatus('APPROVED')">
            <h4 class="success">
              Approved
              <i class="el-icon-success"></i>
            </h4>
          </div>
          <div v-else>
            <el-popover
              placement="top-start"
              title="Rejection details"
              width="400"
              trigger="hover"
            >
              <div>
                <span v-if="getRejectedUser && getRejectedUser.rejected_by"
                  >Rejected by: {{ getRejectedUser.rejected_by }}</span
                >
                <br />
                <span v-if="getRejectedUser && getRejectedUser.rejected_at"
                  >Rejected on:
                  {{ getRejectedUser.rejected_at | globalDateFormat }}</span
                >
                <br />
                <span v-if="getRejectedUser && getRejectedUser.rejected_reason">
                  Reason:
                  {{ getRejectedUser.rejected_reason }}
                </span>
              </div>
              <h4 class="error" slot="reference">
                Rejected
                <i class="el-icon-info"></i>
              </h4>
            </el-popover>
          </div>
        </div>
        <div
          class="bottm-bar mb-1"
          v-else-if="
            this.currentStep &&
            !interactiveQuestionsMode &&
            !isUserApprovingForm
          "
        >
          <div class="progress">
            <div
              v-if="
                formbuilderDetails.steps &&
                formbuilderDetails.steps.length !== 1
              "
            >
              <el-progress
                :format="format"
                :percentage="getCompletionPercentage"
              ></el-progress>
            </div>
            <div class="float-right">{{ getCompletionPercentageRatio }}</div>
          </div>
        </div>
        <div
          class="float-right"
          style="margin-right: 20px; margin-bottom: 10px"
          v-else-if="isUserApprovingForm"
        >
          <div v-if="checkFormbuilderStatus('APPROVED')">
            <h4 class="success">Approved</h4>
          </div>
          <div v-else-if="checkFormbuilderStatus('REJECTED')"></div>
          <div v-else class="d-flex">
            <div v-if="getCurrentUser && getCurrentUser.approved_status">
              <h4 class="success">You have already approved</h4>
            </div>
            <div v-else-if="getCurrentUser && getCurrentUser.rejected_status">
              <h4 class="error">You have already rejected</h4>
            </div>
            <div v-else class="d-flex">
              <el-button
                @click="onApprove"
                size="mini"
                style="background-color: green; color: white"
                class="scaling-button"
                icon="el-icon-check"
                >Approve</el-button
              >
              <el-button
                @click="showRejectConfirm"
                size="mini"
                style="background-color: red; color: white"
                class="scaling-button"
                icon="el-icon-close"
                >Reject</el-button
              >
              <div
                v-if="
                  getReviewerData(currentFormbuilderData) &&
                  checkReviewer(currentFormbuilderData)
                "
              >
                <el-popover
                  placement="top-start"
                  :title="getReviewerData(currentFormbuilderData).title"
                  width="300"
                  trigger="hover"
                >
                  <div>
                    <span
                      v-if="getReviewerData(currentFormbuilderData).label1"
                      >{{
                        getReviewerData(currentFormbuilderData).label1
                      }}</span
                    >
                    <br />
                    <span v-if="getReviewerData(currentFormbuilderData).date">
                      On:
                      {{
                        getReviewerData(currentFormbuilderData).date
                          | globalDateTimeFormat
                      }}
                    </span>
                    <br />
                    <span
                      v-if="getReviewerData(currentFormbuilderData).reason"
                      style="word-break: break-word"
                    >
                      Reason:
                      {{ getReviewerData(currentFormbuilderData).reason }}
                    </span>
                  </div>
                  <div slot="reference">
                    <i class="el-icon-info"></i>
                  </div>
                </el-popover>
              </div>
            </div>
            <formbuilderLogs
              class="warning"
              :currentFormbuilderData="currentFormbuilderData"
            ></formbuilderLogs>
          </div>
        </div>
      </div>
      <el-row
        v-if="
          formbuilderDetails &&
          formbuilderDetails.customization &&
          formbuilderDetails.customization.button_position == 'BOTTOM'
        "
        style="
          border-bottom: 1px solid #e6e6e6;
          display: flex;
          padding: 0px 15px;
        "
        :class="rowAlignment"
      >
        <div>
          <el-button
            class="float-right"
            type="success"
            @click="saveTemplate(currentStepIndex + 1)"
            :style="getBrandingStyles"
            v-if="!isUserApprovingForm && !isExecuteViewStep"
          >
            {{ this.currentStep.has_next ? "Next" : getSaveButtonLabel() }}
            <i
              v-if="this.currentStep.has_next"
              class="fa fa-chevron-right"
              aria-hidden="true"
            ></i>
            <i v-else class="fa fa-check" aria-hidden="true"></i>
          </el-button>
        </div>
      </el-row>
      <el-dialog
        :destroy-on-close="true"
        :visible.sync="reasonForRejectModal"
        title="Reason for reject"
      >
        <el-input
          type="textarea"
          :rows="2"
          placeholder="Reason for reject"
          v-model="reasonForReject"
        ></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button
            @click="rejectFormbuilder(currentFormbuilderData)"
            type="primary"
            :disabled="!reasonForReject.length"
            >Submit</el-button
          >
          <el-button @click="reasonForRejectModal = false">Cancel</el-button>
        </span>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { dataTableAllowedExcecuteFields } from "../templates/formComponentsExecute/index";
// var fieldsList = require("../templates/fields.json");

import TemplateBuilderHelper from "@/mixins/templateBuilderHelper.js";
import NavigationHelper from "@/mixins/navigationHelper";
import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";
import entityRelationshipMixin from "@/mixins/entityRelationshipMixin";
import dataTableStylesHelper from "@/mixins/dataTableStylesHelper";
import userPermissionsHelper from "@/mixins/userPermissionsHelper";

import axios from "@/config/axios";
import { mapGetters } from "vuex";
import { bus } from "../../main";
import { makeSlug } from "../../helpers/appHelper";
export default {
  props: [
    "template",
    "templateData",
    "isExecute",
    "entityDataId",
    "layout",
    "currentStep",
    "currentEntity",
    "entityData",
    "isEdit",
    //  "isApplicationUserSide",
    "currentMenu",
    "currentStepIndex",
    "formbuilderDataId",
    "formbuilderId",
    "formbuilderDetails",
    "currentFormbuilderData",
    "currentFormbuilderDataObj",
    "companyDetails",
  ],
  data() {
    return {
      // fieldsData: [],
      submitted: false,
      field: [],
      interactiveQuestionsMode: false,
      interactiveQuestionsModeType: "vertical",
      disabledFields: [],
      hideFields: [],
      hasLabel: true,
      fieldsData: [],
      loading: false,
      templateStyles: {},
      loadingText: "loading...",
      hasRepeatableTemplate: false,
      formHeight: 600,
      templateRules: [],
      entityRepeatableTemplateData: {},
      repeatableTemplateFieldsData: {},
      max_value: 1000,
      min_value: 0,
      singleCompanyTemplateCheckBoxArray: [],
      templateRuleFields: {},
      form: {},
      entitiesData: [],
      showData: true,
      existedTemplateDataValues: {},
      isApplicationUserSide: false,
      interactiveQuestionsAreDone: false,
      isAllQuestionsAnswered: false,
      stepContacts: [],
      description_shown: false,
      reasonForReject: "",
      reasonForRejectModal: false,
      resetQuestionireForm: false,
      buttonBgColor: "#24a0ed",
      authenticatedUserInfoFromFormbuilder: {},
      filesInfo: {},
      suggestedData: {},
      isUploading: false,
    };
  },
  mixins: [
    TemplateBuilderHelper,
    NavigationHelper,
    FormbuilderHelper,
    entityRelationshipMixin,
    dataTableStylesHelper,
    userPermissionsHelper,
  ],
  components: {
    ...dataTableAllowedExcecuteFields,
    SingleLineTextExecute: () =>
      import("../templates/formComponentsExecute/SingleLineTextExecute.vue"),
    SelectExecute: () =>
      import("../templates/formComponentsExecute/SelectExecute.vue"),
    HorizontalLineExecute: () =>
      import("../templates/formComponentsExecute/HorizontalLineExecute"),
    DateExecute: () => import("../templates/formComponentsExecute/DateExecute"),
    DateTimeExecute: () =>
      import("../templates/formComponentsExecute/DateTimeExecute"),
    DateTimeRangeExecute: () =>
      import("../templates/formComponentsExecute/DateTimeRangeExecute"),
    DateRangeExecute: () =>
      import("../templates/formComponentsExecute/DateRangeExecute"),
    TimeExecute: () => import("../templates/formComponentsExecute/TimeExecute"),
    TimeRangeExecute: () =>
      import("../templates/formComponentsExecute/TimeRangeExecute"),
    FileExecute: () => import("../templates/formComponentsExecute/FileExecute"),
    MultiLineTextExecute: () =>
      import("../templates/formComponentsExecute/MultiLineTextExecute"),
    RadioGroupExecute: () =>
      import("../templates/formComponentsExecute/RadioButtonGroupExecute.vue"),
    MultiSelectExecute: () =>
      import("../templates/formComponentsExecute/MultiSelectExecute"),
    NumberExecute: () =>
      import("../templates/formComponentsExecute/NumberExecute"),
    YesOrNoExecute: () =>
      import("../templates/formComponentsExecute/YesOrNoExecute"),
    HeadingExecute: () =>
      import("../templates/formComponentsExecute/HeadingExecute"),
    CheckBoxExecute: () =>
      import("../templates/formComponentsExecute/CheckBoxExecute"),
    RadioExecute: () =>
      import("../templates/formComponentsExecute/RadioExecute"),
    ESignatureExecute: () =>
      import("../templates/formComponentsExecute/ESignatureExecute"),
    ParagraphExecute: () =>
      import("../templates/formComponentsExecute/ParagraphExecute"),
    GlobalVariableExecute: () =>
      import("../templates/formComponentsExecute/GlobalVariableExecute"),
    EntityVariableExecute: () =>
      import("../templates/formComponentsExecute/EntityVariableExecute"),
    EntityExecute: () =>
      import("../templates/formComponentsExecute/EntityExecute"),
    SingleLineContentExecute: () =>
      import("../templates/formComponentsExecute/SingleLineContentExecute"),
    AuthorizedSignatureExecute: () =>
      import("../templates/formComponentsExecute/AuthorizedSignatureExecute"),
    ImageExecute: () =>
      import("../templates/formComponentsExecute/ImageExecute"),
    VideoExecute: () =>
      import("../templates/formComponentsExecute/VideoExecute"),
    FormulaExecute: () =>
      import("../templates/formComponentsExecute/FormulaExecute"),
    ActionButtonExecute: () =>
      import("../templates/formComponentsExecute/ActionButtonExecute"),
    HtmlExecute: () => import("../templates/formComponentsExecute/HtmlExecute"),
    ListExecute: () => import("../templates/formComponentsExecute/ListExecute"),
    DIVExecute: () =>
      import("../templates/formComponentsExecute/DIVExecute.vue"),
    PhoneCountryCodeExecute: () =>
      import("../templates/formComponentsExecute/PhoneCountryCodeExecute.vue"),
    CurrencyExecute: () =>
      import("../templates/formComponentsExecute/CurrencyExecute.vue"),
    WeekDaysExecute: () =>
      import("../templates/formComponentsExecute/WeekDaysExecute.vue"),
    StarRatingExecute: () =>
      import("../templates/formComponentsExecute/StarRatingExecute.vue"),
    CheckBoxGroupExecute: () =>
      import("../templates/formComponentsExecute/CheckBoxGroupExecute.vue"),
    AggregateFunctionExecute: () =>
      import("../templates/formComponentsExecute/AggregateFunctionExecute.vue"),
    DataTableExecute: () =>
      import("../templates/formComponentsExecute/DataTableExecute.vue"),
    RepeatableTemplatePreview: () =>
      import("./formbuilderRepeatableTemplatePreview"),
    PreviewInteractiveTemplateForm: () =>
      import("@/components/templates/PreviewInteractiveTemplateForm"),
    ConcatenateExecute: () =>
      import("../templates/formComponentsExecute/ConcatenateExecute.vue"),
    RandomTextExecute: () =>
      import("../templates/formComponentsExecute/RandomTextExecute.vue"),
    QuestionExecute: () =>
      import("../templates/formComponentsExecute/QuestionExecute"),
    formbuilderLogs: () => import("@/components/widgets/formbuilderLogsWidget"),
    AudioExecute: () =>
      import("../templates/formComponentsExecute/AudioExecute.vue"),
    EntityTableExecute: () =>
      import("../templates/formComponentsExecute/EntityTableExecute"),
    CaptchaExecute: () =>
      import("../templates/formComponentsExecute/CaptchaExecute.vue"),
    QRExecute: () => import("../templates/formComponentsExecute/QRExecute.vue"),
    QRReaderExecute: () =>
      import("../templates/formComponentsExecute/QRReaderExecute.vue"),
  },
  computed: {
    rowAlignment() {
      const position =
        this.formbuilderDetails?.customization?.button_horizontal_position;

      if (!position) {
        // If the value is not available, you can return a fallback or keep it undefined
        return "justify-end"; // Default value
      }

      switch (position) {
        case "CENTER":
          return "justify-center";
        case "RIGHT":
          return "justify-end";
        case "LEFT":
          return "justify-start";
        default:
          return "justify-start"; // Fallback if the value is unexpected
      }
    },
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("entities", ["getEntityDataById"]),
    ...mapGetters("templatesData", [
      "getRequiredTemplatesData",
      "getDuplicateDataCheck",
      "getUploadAndUpdateDocument",
      "getTemplatesDataUpdateStatus",
      "getUserTemplateDataUpdateErrors",
      "getNewEntityCreateError",
      "getEntityDataCreateByTemplateStatus",
      "getNewEntityData",
    ]),
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getDefaultDateFormat",
      "getActiveContactType",
    ]),
    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getCurrentEntityRelationships",
    ]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
    ...mapGetters("filters", ["getSingleFilterData", "getAllEntityFilters"]),
    ...mapGetters("formbuilderData", [
      "getNewWorkflowData",
      "getWorkflowTemplateDataAddStatus",
      "getNewFormbuilderData",
      "getUserFormbuilderData",
      "getFormbuilderDataUpdateStatus",
      "getFormbuilderDataCreateByTemplateStatus",
      "getNewFormbuilderDataError",
      "getFormbuilderDataUpdateError",
    ]),
    ...mapGetters("formBuilders", [
      "getFormBuilderAddStatus",
      "getFormBuilder",
      "getFormBuilderFillingInfo",
    ]),
    ...mapGetters("menuManagementV2", ["getEntityMenu"]),
    ...mapGetters("contacts", [
      "getCreateContactsForFormbuilderData",
      "getCreateContactsForFormbuilderErrors",
      "getAllContacts",
    ]),
    getContainerStyle() {
      // if (this.layout == "STANDARD") {
      //   return "max-height: calc(100vh - 550px) !important";
      // }
      // return "max-height: calc(100vh - 100px) !important";
      return `max-height: calc(92vh - 100px) !important; scrollbar-width: thin; overflow-y:auto !important; ${this.getElementStyle}`;
    },
    getRepeatableContainerStyle() {
      return "max-height: calc(100vh - 300px) !important; overflow-x: hidden !important;";
    },
    //getStylediv() {
    // return (
    //   `height:${this.formHeight + (this.getIsMobile ? 300 : 300)}px;` +
    //   this.getElementStyle
    // );
    //return `height:${this.getIsMobile ? 800 : 450}px;` + this.getElementStyle;
    //},
    getStylediv() {
      const height =
        this.getIsMobile &&
        this.formbuilderDetails?.customization?.show_title_bar
          ? "90vh"
          : "78vh";
      return `height: ${height}; ${this.getElementStyle}`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.templateStyles) {
        let type = this.templateStyles.border_type
          ? this.templateStyles.border_type
          : "solid";
        let size = this.templateStyles.border_size
          ? this.templateStyles.border_size + "px"
          : "0px";
        let color = this.templateStyles.border_color
          ? this.templateStyles.border_color
          : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.templateStyles.background
          ? ` background: ${this.templateStyles.background} !important;`
          : "";
      }
      return borderStyle;
    },
    getFieldsData() {
      return this.fieldsData.map((field) => {
        if (field.key && field.key.includes("#")) {
          field.key = field.key.split("#")[1];
        }
        return field;
      });
    },
    getEntityId() {
      return this.currentEntity?._id || this.$route.params.entity_id;
    },
    getRquiredFieldsCount() {
      return this.fieldsData.filter(
        (e) =>
          e.validations &&
          e.validations.required &&
          this.form[e.key] &&
          this.fieldFilledByCurrentUser(e.filled_by)
      ).length;
    },
    getTotalFieldsCount() {
      return this.fieldsData.filter(
        (e) =>
          e.validations &&
          e.validations.required &&
          !e.value &&
          this.fieldFilledByCurrentUser(e.filled_by)
      ).length;
    },
    getSavedSteps() {
      this.applyDefaultValues();
      if (this.currentFormbuilderData?.formbuilder_steps_data) {
        return this.currentFormbuilderData.formbuilder_steps_data;
      }
      return [];
    },
    getCompletedSteps() {
      if (this.getSavedSteps && this.getSavedSteps.length) {
        return this.getSavedSteps.filter((e) => e.completion_status);
      }
      return [];
    },
    getCompletionPercentageRatio() {
      if (
        this.getCompletedSteps &&
        this.getAllSteps &&
        this.getCompletedSteps.length &&
        this.getAllSteps.length
      ) {
        return this.getCompletedSteps.length + "/" + this.getAllSteps.length;
      }
      return "";
    },
    getCompletionPercentage() {
      let percentage = 0;
      if (
        this.getCompletedSteps &&
        this.getAllSteps &&
        this.getCompletedSteps.length &&
        this.getAllSteps.length
      ) {
        percentage =
          (this.getCompletedSteps.length / this.getAllSteps.length) * 100;
      }
      return percentage;
    },
    checkIsRepeatable() {
      if (this.currentStep?.settings?.is_repeatable) {
        return true;
      }
      return false;
    },
    getAllSteps() {
      if (this.formbuilderDetails?.steps) {
        return this.formbuilderDetails.steps;
      }
      return [];
    },
    getMinNoOfRecords() {
      if (
        this.currentStep?.settings?.linked_min_field &&
        this.currentFormbuilderDataObj
      ) {
        let min = this.calculateMinNoOfRecords(
          this.currentStep?.settings?.linked_min_field,
          this.currentStep?.settings?.min_records
        );
        return min;
      } else if (this.currentStep?.settings?.min_records) {
        return parseInt(this.currentStep.settings.min_records);
      }
      return 1;
    },
    getMaxNoOfRecords() {
      if (
        this.currentStep?.settings?.linked_field &&
        this.currentFormbuilderDataObj
      ) {
        let max = this.calculateMaxNoOfRecords(
          this.currentStep?.settings?.linked_field,
          this.currentStep?.settings?.max_records
        );
        if (max < this.getMinNoOfRecords) {
          return this.getMinNoOfRecords;
        }
        return max;
      } else if (this.currentStep?.settings?.max_records) {
        let max = parseInt(this.currentStep.settings.max_records);
        if (max < this.getMinNoOfRecords) {
          return this.getMinNoOfRecords;
        }
        return max;
      }
      return 1000;
    },
    isFormbuilderCompleted() {
      if (this.getMinNoOfRecords) {
        return (
          this.entitiesData &&
          this.entitiesData.length >= this.getMinNoOfRecords
        );
      }
      return true;
    },
    isApprovalForm() {
      return this.formbuilderDetails?.is_approval_form || false;
    },
    isUserApprovingForm() {
      return this.$route.name == "GuestFormbuilderApprovalExecute";
    },
    isExecuteViewStep() {
      return this.$route.name == "GuestFormbuilderExecuteViewStep";
    },
    getRejectedUser() {
      if (this.currentFormbuilderData?.approval_users) {
        return this.currentFormbuilderData?.approval_users.find(
          (e) => e.rejected_status
        );
      }
      return null;
    },
    getCurrentUser() {
      if (this.currentFormbuilderData?.approval_users) {
        return this.currentFormbuilderData?.approval_users.find(
          (e) =>
            (this.getAuthenticatedUser &&
              e.approved_by &&
              e.approved_by.toLowerCase() ==
                this.getAuthenticatedUser.email.toLowerCase()) ||
            (e.rejected_by &&
              e.rejected_by.toLowerCase() ==
                this.getAuthenticatedUser.email.toLowerCase())
        );
      }
      return null;
    },
    getBrandingStyles() {
      let style = "";
      if (this.companyDetails?.button?.button_type === "RECTANGLE") {
        style += "border-radius: 5px;";
      } else {
        style += "border-radius: 50px;";
      }
      return style;
    },
    getIsView() {
      if (this.$route?.name === "GuestFormbuilderView") {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.setTemplateInfo(this.templateData);
    if (this.$refs.formBuilder && this.$refs.formBuilder.clientWidth) {
      this.currentFormBuilderWidth = this.$refs.formBuilder.clientWidth;
    }
  },
  created() {
    this.$bus.$on("uploadStarted", this.handleUploadStarted);
    this.$bus.$on("uploadFinished", this.handleUploadFinished);
  },
  methods: {
    applyDefaultValues() {
      let updatedForm = this.mapDefaultValues(
        this.fieldsData,
        JSON.parse(JSON.stringify(this.form)),
        true
      );
      Object.keys(updatedForm).forEach((key) => {
        this.$set(this.form, key, updatedForm[key]);
      });
    },
    checkFieldFilled(field, data) {
      let showError = false;
      if (
        field?.field_assignable == "read_only" &&
        field?.input_type == "ENTITY_VARIABLE"
      ) {
        return !showError;
      }
      if (field.inputType == "NUMBER") {
        data[field.key] = parseFloat(data[field.key]);
        if (!this.isNumber(data[field.key])) {
          showError = true;
        } else if (
          this.checkIsRequired(field) &&
          field.properties &&
          field.properties.number_type &&
          field.properties.number_type === "PERCENTAGE"
        ) {
          //percentage field validation
        } else if (
          this.checkIsRequired(field) &&
          this.checkIsNotFilled(field, data)
        ) {
          showError = true;
        }
      } else {
        if (
          (!data[field.key] || !data[field.key].length) &&
          !this.isNumber(data[field.key])
        ) {
          showError = true;
        }
      }
      return !showError;
    },
    checkDataTableRequiredFields(data, field) {
      let message = "Please fill required fields in " + field.label;
      let success = true,
        fieldRules = (field.rules || []).filter((rule) => {
          if (rule.actions) {
            return rule.actions.find(
              (c) => c.selectField == "NOTIFICATION" && c.restrict_save
            )
              ? true
              : false;
          }
        });
      (data || []).forEach((form, i) => {
        if (field.data_table_columns) {
          field.data_table_columns.forEach((fl) => {
            if (
              fl.validations.required &&
              (!fl?.properties?.filed_content ||
                fl?.properties?.filed_content == "")
            ) {
              let filled = this.checkFieldFilled(fl, form);
              if (!filled) {
                success = false;
                message =
                  "Please fill " +
                  fl.label +
                  " in " +
                  field.label +
                  " " +
                  (i + 1) +
                  "  row";
              }
            }
            if (
              fl.inputType == "FILE" &&
              fl.validations.multiple &&
              form[fl.key] &&
              form[fl.key].files &&
              form[fl.key].files.length &&
              !this.checkInBetween(
                form[fl.key].files.length,
                fl.validations.min_document_files,
                fl.validations.max_document_files
              )
            ) {
              this.fileMinValue = fl.validations.min_document_files;
              this.fileMaxValue = fl.validations.max_document_files;
              success = false;
              message =
                `Please upload minimum '${this.fileMinValue}' files or maximum '${this.fileMaxValue}' files , to meet the minimum & maximum file(s) requirement.` +
                "  row";
            }
          });
        }
        if (fieldRules && fieldRules.length) {
          fieldRules.forEach((rule) => {
            let results = [];
            rule.conditions.forEach((condition) => {
              results.push(
                this.checkCondition(condition, form, field.data_table_columns)
              );
            });
            if (rule.query_type == "AND" && results.every((el) => el)) {
              message = "";
              let notify = rule.actions.find(
                (c) => c.selectField == "NOTIFICATION" && c.restrict_save
              );
              message = notify.message + " on " + (i + 1) + "  row";
              success = false;
            } else if (rule.query_type == "OR" && results.some((el) => el)) {
              message = "";
              let notify = rule.actions.find(
                (c) => c.selectField == "NOTIFICATION" && c.restrict_save
              );
              message = notify.message + " on " + (i + 1) + " row";
              success = false;
            }
          });
        }
      });
      return {
        message,
        success,
      };
    },
    getRequiredStatus(field) {
      if (this.submitted) {
        // Assuming 'vm' is the Vue component instance
        let arrayInputTypes = ["ENTITY", "WEEKDAYS"];
        if (!field?.validations?.required) {
          return false;
        }
        if (
          arrayInputTypes.includes(field.input_type) &&
          field.allow_multiple &&
          !this.form[field.key]?.length
        ) {
          return true;
        } else if (
          field.input_type == "MULTI_SELECT" &&
          !this.form[field.key]?.length
        ) {
          return true;
        } else if (!this.form[field.key]) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    calculateMinNoOfRecords(linked_min_field, min_records) {
      if (linked_min_field && this.currentFormbuilderDataObj) {
        let [stepId, temp_id, key] = linked_min_field.split("#");
        if (
          this.currentFormbuilderDataObj[stepId] &&
          this.currentFormbuilderDataObj[stepId][temp_id] &&
          this.currentFormbuilderDataObj[stepId][temp_id][key]
        ) {
          let min = parseInt(
            this.currentFormbuilderDataObj[stepId][temp_id][key]
          );
          return min;
        }
        return 1;
      } else if (min_records) {
        return parseInt(min_records);
      }
      return 1;
    },
    calculateMaxNoOfRecords(linked_field, max_records) {
      if (linked_field && this.currentFormbuilderDataObj) {
        let [stepId, temp_id, key] = linked_field.split("#");
        if (
          this.currentFormbuilderDataObj[stepId] &&
          this.currentFormbuilderDataObj[stepId][temp_id] &&
          this.currentFormbuilderDataObj[stepId][temp_id][key]
        ) {
          return parseInt(this.currentFormbuilderDataObj[stepId][temp_id][key]);
        }
        return 1000;
      } else if (max_records) {
        return parseInt(max_records);
      }
      return 1000;
    },
    getSaveButtonLabel() {
      if (this.formbuilderDetails?.customization?.finish_button_label) {
        return this.formbuilderDetails.customization.finish_button_label;
      }
      return "Finish";
    },
    stayAndReviewPage() {
      this.isAllQuestionsAnswered = false;
      this.resetQuestionireForm = true;
    },
    checkFormbuilderStatus(status) {
      if (this.currentFormbuilderData?.approval_status && status) {
        return this.currentFormbuilderData.approval_status == status;
      }
      return false;
    },
    showRejectConfirm() {
      this.reasonForReject = "";
      this.reasonForRejectModal = true;
    },
    async rejectFormbuilder(formbuilderData) {
      this.loading = true;
      let currentuserIndex = this.getCurrentApprover(formbuilderData);
      if (currentuserIndex > -1) {
        let params = this.getRejectParams(
          formbuilderData,
          currentuserIndex,
          this.reasonForReject
        );
        await this.$store.dispatch(
          "formbuilderData/updateFormbuilderData",
          params
        );
        if (this.getFormbuilderDataUpdateStatus) {
          this.$message({
            message: "Rejected successfully!",
            type: "success",
          });
          this.reasonForReject = "";
          this.reasonForRejectModal = false;
          //have to change the action
          window.location.reload();
        } else {
          this.$notify.warning({
            title: "Warning",
            message:
              this.getFormbuilderDataUpdateError.message ||
              "Error while rejecting",
          });
        }
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "You are not approver",
        });
      }
      this.loading = false;
    },
    async onApprove() {
      this.$confirm("Are you sure to approve?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.approveFormbuilder(this.currentFormbuilderData);
      });
    },
    async approveFormbuilder(formbuilderData) {
      this.loading = true;
      let currentuserIndex = this.getCurrentApprover(formbuilderData);
      if (currentuserIndex > -1) {
        let params = this.getApprovalParams(formbuilderData, currentuserIndex);
        await this.$store.dispatch(
          "formbuilderData/updateFormbuilderData",
          params
        );
        if (this.getFormbuilderDataUpdateStatus) {
          this.$message({
            message: "Approved successfully!",
            type: "success",
          });
        } else {
          this.$notify.warning({
            title: "Warning",
            message:
              this.getFormbuilderDataUpdateError.message ||
              "Error while approving",
          });
        }
        //have to change the action
        window.location.reload();
      } else {
        this.$notify.warning({
          title: "Warning",
          message: "You are not approver",
        });
      }
      this.loading = false;
    },
    removeRowData(index) {
      this.entitiesData.splice(parseInt(index) - 1, 1);
    },
    addNewRepeatableData() {
      let newIndexKey = (Math.random() + 1).toString(36).substring(7);
      this.entitiesData.push({ indexKey: newIndexKey });
      this.rulesEventEmitter();
    },
    format() {
      let value = "";
      return value;
    },
    updateInteractiveQuestionFillingStatus(status) {
      if (!this.entityDataId) {
        if (status == true) {
          this.isAllQuestionsAnswered = true;
        }
      }
    },
    getExistedStyle(field) {
      return field.style || undefined;
    },
    updateTopForBelowElements(data) {
      this.fieldsData.forEach((e, index) => {
        if (e.listen_height) {
          if (data) {
            this.$set(
              this.fieldsData[index],
              "style",
              this.getStyle(e, index, data.height)
            );
          } else {
            this.$set(this.fieldsData[index], "style", this.getStyle(e, index));
          }
        }
      });
    },
    checkIsMinValue(data) {
      if (data && data.min_selection > 0) {
        return true;
      } else {
        return false;
      }
    },
    checkIsRequired(data) {
      if (data.allow_multiple) {
        if (data.min_value > 0) {
          return true;
        }
        return false;
      }
      return data?.validations?.required ? true : false;
    },
    updateTableFieldData(details) {
      this.$set(this.form, details.key, details.data);
    },

    checkPerimission(access) {
      if (this.isApplicationUserSide) {
        if (this.getEntityPermission && this.currentMenu) {
          if (
            this.currentMenu?.entity_type == "ENTITY" &&
            this.getEntityPermission.other_entities &&
            this.templateData?._id &&
            this.getEntityPermission.other_entities[this.getEntityDataById._id]
              ?.templates?.[this.templateData._id] &&
            this.getEntityPermission.other_entities[
              this.getEntityDataById._id
            ]?.templates?.[this.templateData._id].indexOf(access) === -1
          ) {
            return false;
          } else if (
            this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
            this.getEntityPermission.relational_entities &&
            this.getEntityPermission.relational_entities[
              this.getEntityDataById._id
            ]?.permissions &&
            this.getEntityPermission.relational_entities[
              this.getEntityDataById._id
            ]?.permissions.indexOf(access) === -1
          ) {
            return false;
          }
        }
        return true;
      } else {
        if (access == "EDIT") {
          access = "UPDATE";
        }
        if (
          this.$route.query?.filter &&
          this.getSingleFilterData &&
          this.getEntityDataById &&
          this.getEntityDataById._id == this.getSingleFilterData.entity_id &&
          this.getSingleFilterData?.permissions?.access_set
        ) {
          if (!this.getSingleFilterData.permissions.access_set.length) {
            return true;
          }
          return (
            this.getSingleFilterData.permissions.access_set.indexOf(access) !==
            -1
          );
        }
        return true;
      }
    },
    goToEditMode() {
      let url = this.urlBuilder(this.$route.query);
      if (this.isApplicationUserSide) {
        url = `/ap/entity-execute/${this.currentEntity._id}?` + url;
      } else {
        url = `/entity/edit/${this.currentEntity._id}?` + url;
      }
      window.location.href = url;
    },
    async setDataToEntityVariables(data, parent, label, changed) {
      if (label) {
        this.$set(this.form, parent.key + "/name", label);
      }
      this.getFieldsData.forEach((field) => {
        if (
          field.relationship_key == parent.key &&
          data?.entityData &&
          (field.field_assignable == "read_only" ||
            field.inputType == "ENTITY" ||
            !this.form[field.key] ||
            changed)
        ) {
          if (
            field.global_variable_entity_field.includes("~") ||
            field.variable_action === "RESOURCE_AVERAGE"
          ) {
            // we need to skip assign data part we if the field is relationship field
            // becase we are setting entity data. but entity data don't have relation data by default. it makes value as null.
            return;
          }
          if (field.global_variable_entity_field) {
            let templateId = "";
            let key = "";
            let value = "";
            let valueName = "";
            if (field.global_variable_entity_field.includes("#")) {
              templateId = field.global_variable_entity_field.split("#")[0];
              key = field.global_variable_entity_field.split("#")[1];
            } else {
              templateId = field.global_variable_entity_field;
            }

            if (
              data.entityData &&
              data.entityData[templateId] &&
              data.entityData[templateId][key]
            ) {
              value = data.entityData[templateId][key];

              if (field.inputType == "ENTITY") {
                valueName = data.entityData[templateId][key + "/name"];
                this.$set(this.form, field.key + "/default", [value]);
              }
            }
            if (this.form[field.key]) {
              if (data.entityData[templateId][key + "_code"]) {
                this.form[field.key + "_code"] =
                  data.entityData[templateId][key + "_code"];
              }
              this.form[field.key] = value;
              if (valueName) {
                this.form[field.key + "/name"] = valueName;
              }
            } else {
              this.$set(this.form, field.key, value);
              if (valueName) {
                this.$set(this.form, field.key + "/name", valueName);
              }
            }
          }
        }
      });
      // if (!this.form.parent_data) {
      //   this.form.parent_data = {};
      // }
      // if (entityId) {
      //   this.form.parent_data[entityId] = data;
      // } else {
      //   this.form.parent_data = {};
      // }
      this.rulesEventEmitter();
    },
    async unsetEntityVariables(parent) {
      this.getFieldsData.forEach((field) => {
        if (field.relationship_key == parent.key) {
          this.$set(this.form, field.key, "");
        }
      });
      this.rulesEventEmitter();
    },
    rulesEventEmitter() {
      this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
    },
    checkIsDisabled(field) {
      return this.disabledFields.find((e) => e == field.key) ? true : false;
    },
    checkhideFields(field) {
      return this.hideFields.find((e) => e == field.key) ? true : false;
    },
    checkIsNotFilled(field, data) {
      if (field.allow_multiple) {
        if (field.min_value > 0) {
          if (
            data &&
            data[field.key] &&
            typeof data[field.key] == "object" &&
            data[field.key].length >= field.min_value
          ) {
            return false;
          }
          return true;
        }
        return false;
      } else {
        if (data && data[field.key]) {
          return false;
        }
        return true;
      }
    },
    isNumber(value) {
      return typeof value === "number" || !isNaN(parseFloat(value));
    },
    checkRequiredFields() {
      this.requiredFields = [];
      this.minValue = "";
      this.fileMinValue = "";
      this.fileMaxValue = "";
      let showError = false;
      let invalidError = false;
      let emailFormatError = false;
      let checkboxMinMaxCheck = false;
      let fileMinMaxCheck = false;
      let customErrorMessage = "";
      let checkBoxLabel = "";
      let multiselectMinMaxCheck = false;
      let multiselectLabel = "";
      let reEmail =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!this.hasRepeatableTemplate) {
        this.entitiesData.push(this.form);
      }
      this.entitiesData.forEach((data) => {
        this.fieldsData.forEach((field) => {
          if (typeof customErrorMessage === "undefined") {
            customErrorMessage = "";
          }
          if (!this.fieldFilledByCurrentUser(field.filled_by)) {
            return;
          }
          let checkBoxGroup =
            this.templateData?.sections[0]?.checkBoxGroup.find(
              (e) => e.key === field.group_key
            );
          if (
            this.checkhideFields(field) ||
            field?.properties?.filed_content == "Hide"
          ) {
            return;
          }
          if (field.input_type === "AUTO_INCREMENT_NUMBER") {
            //do nothing
          } else if (field.input_type === "CAPTCHA") {
            if (!data[field.key]) {
              showError = true;
              customErrorMessage =
                "Captcha verification required! Please complete the captcha verification";
              this.requiredFields.push(field.label);
            }
          } else if (
            field.input_type == "WEEKDAYS" &&
            this.checkIsRequired(field) &&
            this.checkIsNotFilled(field, data)
          ) {
            if (field.allow_multiple) {
              customErrorMessage =
                "Please select " +
                field.min_value +
                " options for " +
                field.label +
                " field";
            }
            showError = true;
            this.requiredFields.push(field.label);
          } else if (field.input_type == "NUMBER") {
            data[field.key] = parseFloat(data[field.key]);
            if (!this.isNumber(data[field.key])) {
              showError = true;
              customErrorMessage =
                field.label +
                " field value is not a number.Please enter valid numbers. ";
            } else if (
              this.checkIsRequired(field) &&
              field.properties &&
              field.properties.number_type &&
              field.properties.number_type === "PERCENTAGE"
            ) {
              //percentage field validation
            } else if (
              this.checkIsRequired(field) &&
              this.checkIsNotFilled(field, data)
            ) {
              showError = true;
              if (!customErrorMessage.includes(field.label)) {
                if (customErrorMessage.includes("Please fill")) {
                  customErrorMessage = customErrorMessage + ", " + field.label;
                } else {
                  customErrorMessage = "Please fill " + field.label;
                }
              }
              this.requiredFields.push(field.label);
            }
          } else if (field.input_type == "ENTITY_TABLE") {
            let { min } = this.getEntityTableDetails(field, data);
            let l = data[field.key] ? data[field.key].length : 0;
            if (min > l) {
              customErrorMessage =
                "Please add " + (min - l) + " more rows in " + field.label;
              showError = true;
              invalidError = true;
              if (this.requiredFields.indexOf(field.label) == -1) {
                this.requiredFields.push(field.label);
              }
            }
          } else if (
            field.validations &&
            field.validations.required &&
            // !data[field.key] &&
            ((!this.checkIsDisabled(field) &&
              !this.checkhideFields(field) &&
              !this.hasRepeatableTemplate) ||
              (this.hasRepeatableTemplate &&
                !this.checkFieldFillStatus(field, data)))
          ) {
            if (
              field.validations.type &&
              field.validations.type == "ARRAY" &&
              field.key &&
              data[field.key] &&
              !data[field.key].length
            ) {
              showError = true;
              if (!customErrorMessage.includes(field.label)) {
                if (customErrorMessage.includes("Please fill")) {
                  customErrorMessage = customErrorMessage + ", " + field.label;
                } else {
                  customErrorMessage = "Please fill " + field.label;
                }
              }
              this.requiredFields.push(field.label);
            } else if (field.key && !data[field.key]) {
              showError = true;
              if (!customErrorMessage.includes(field.label)) {
                if (customErrorMessage.includes("Please fill")) {
                  customErrorMessage = customErrorMessage + ", " + field.label;
                } else {
                  customErrorMessage = "Please fill " + field.label;
                }
              }
              this.requiredFields.push(field.label);
            } else if (field.input_type == "QUESTION") {
              if (
                (field.key && !data[field.key]) ||
                data[field.key].length == 0
              ) {
                showError = true;
                if (!customErrorMessage.includes(field.label)) {
                  if (customErrorMessage.includes("Please fill")) {
                    customErrorMessage =
                      customErrorMessage + ", " + field.label;
                  } else {
                    customErrorMessage = "Please fill " + field.label;
                  }
                }
                this.requiredFields.push(field.label);
              }
            }
          } else if (field?.inputType == "DATA_TABLE") {
            let checkedData = this.checkDataTableRequiredFields(
              data[field.key],
              field
            );
            if (!checkedData?.success) {
              customErrorMessage = checkedData.message;
              showError = true;
            }
          }
          if (field?.inputType == "ENTITY_TABLE") {
            let checkedData = this.checkDataTableRequiredFields(
              data[field.key],
              field
            );
            if (!checkedData?.success) {
              customErrorMessage = checkedData.message;
              showError = true;
            }
          }

          if (
            this.templateData &&
            this.templateData.type == "STANDARD" &&
            this.templateData.slug == "contactdetails"
          ) {
            let NameRe = /[a-zA-Z]{1,}/ || /(?:[A-Za-z].?\d|\d.?[A-Za-z]){1,}/;
            // let PhoneRe =
            //   /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s]{0,1}[0-9]{3}[-\s]{0,1}[0-9]{4}$/;
            if (field.key == "first_name") {
              if (
                !NameRe.test(data[field.key]) ||
                !data[field.key] ||
                !data[field.key].trim().length
              ) {
                customErrorMessage = "Invalid first name";
                emailFormatError = false;
                checkboxMinMaxCheck = false;
                multiselectMinMaxCheck = false;
                fileMinMaxCheck = false;
                showError = true;
                invalidError = true;
                if (this.requiredFields.indexOf(field.label) == -1) {
                  this.requiredFields.push(field.label);
                }
              }
            } else if (field.key == "last_name") {
              if (
                !NameRe.test(data[field.key]) ||
                !data[field.key] ||
                !data[field.key].trim().length
              ) {
                customErrorMessage = "Invalid last name";
                emailFormatError = false;
                checkboxMinMaxCheck = false;
                multiselectMinMaxCheck = false;
                fileMinMaxCheck = false;
                showError = true;
                invalidError = true;
                if (this.requiredFields.indexOf(field.label) == -1) {
                  this.requiredFields.push(field.label);
                }
              }
            }
            //  else if (field.key == "phone_number") {
            //   if (field && data[field.key] && !PhoneRe.test(data[field.key])) {
            //     customErrorMessage = "Invalid Phone Number";
            //     emailFormatError = false;
            //     checkboxMinMaxCheck = false;
            //     fileMinMaxCheck = false;
            //     showError = true;
            //     invalidError = true;
            //     if (this.requiredFields.indexOf(field.label) == -1) {
            //       this.requiredFields.push(field.label);
            //     }
            //   }
            // }
            else if (field.key == "email") {
              if (
                data[field.key] &&
                (!data[field.key].length || !reEmail.test(data[field.key]))
              ) {
                emailFormatError = true;
                checkboxMinMaxCheck = false;
                multiselectMinMaxCheck = false;
                fileMinMaxCheck = false;
                showError = true;
                invalidError = true;
                this.requiredFields.push(field.label);
              }
            }
          }
          if (
            field.inputType == "FILE" &&
            field.validations.multiple &&
            data[field.key] &&
            data[field.key].files &&
            data[field.key].files.length &&
            !this.checkInBetween(
              data[field.key].files.length,
              field.validations.min_document_files,
              field.validations.max_document_files
            )
          ) {
            checkboxMinMaxCheck = false;
            fileMinMaxCheck = true;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.fileMinValue = field.validations.min_document_files;
            this.fileMaxValue = field.validations.max_document_files;
          }
          if (field.inputType === "CHECKBOX" && checkBoxGroup?.min_value > 0) {
            if (
              this.checkCheckBoxMinValue(field, data) === 0 ||
              this.checkCheckBoxMinValue(field, data) < checkBoxGroup.min_value
            ) {
              checkboxMinMaxCheck = true;
              fileMinMaxCheck = false;
              showError = true;
              invalidError = true;
              this.requiredFields.push(field.label);
              this.minValue = checkBoxGroup.min_value;
            }
          }
          if (
            field.inputType == "CHECKBOX_GROUP" &&
            data[field.key] &&
            data[field.key].length &&
            data[field.key].length < this.getMinValue(field, data)
          ) {
            customErrorMessage = field.default_error_message;
            checkBoxLabel = field.label;
            checkboxMinMaxCheck = true;
            multiselectMinMaxCheck = false;
            fileMinMaxCheck = false;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.minValue = this.getMinValue(field, data);
          } else if (
            field.inputType == "CHECKBOX_GROUP" &&
            (!data[field.key] || !data[field.key].length)
          ) {
            customErrorMessage = field.default_error_message;
            checkBoxLabel = field.label;
            checkboxMinMaxCheck = true;
            multiselectMinMaxCheck = false;
            fileMinMaxCheck = false;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.minValue = this.getMinValue(field, data);
          }
          if (
            field.inputType === "MULTI_SELECT" &&
            data[field.key] &&
            data[field.key].length &&
            data[field.key].length < this.getMinValue(field, data)
          ) {
            customErrorMessage = field.default_error_message;
            multiselectLabel = field.label;
            multiselectMinMaxCheck = true;
            checkboxMinMaxCheck = false;
            fileMinMaxCheck = false;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.minValue = this.getMinValue(field, data);
          } else if (
            field.inputType === "MULTI_SELECT" &&
            this.getMinValue(field, data) > 0 &&
            (!data[field.key] || !data[field.key].length)
          ) {
            customErrorMessage = field.default_error_message;
            multiselectLabel = field.label;
            multiselectMinMaxCheck = true;
            checkboxMinMaxCheck = false;
            fileMinMaxCheck = false;
            showError = true;
            invalidError = true;
            this.requiredFields.push(field.label);
            this.minValue = this.getMinValue(field, data);
          } else if (
            field.inputType === "MULTI_SELECT" &&
            (this.getMinValue(field, data) === 0 ||
              this.getMinValue(field, data) === null) &&
            this.getMaxValue(field, data) === null &&
            (!data[field.key] || !data[field.key].length)
          ) {
            customErrorMessage = "";
            multiselectLabel = "";
            multiselectMinMaxCheck = false;
            fileMinMaxCheck = false;
            showError = false;
            invalidError = false;
          }
        });
      });
      return {
        showError,
        invalidError,
        emailFormatError,
        checkboxMinMaxCheck,
        customErrorMessage,
        checkBoxLabel,
        fileMinMaxCheck,
        multiselectMinMaxCheck,
        multiselectLabel,
      };
    },
    handleUploadStarted() {
      this.isUploading = true;
    },
    handleUploadFinished() {
      this.isUploading = false;
    },
    getMinValue(field, data) {
      if (field && data[field.key + "/min_value"]) {
        return data[field.key + "/min_value"];
      }
      return field.min_selection || undefined;
    },
    checkCheckBoxMinValue(fields, data) {
      let groupSelectedFields = [];
      if (this.fieldsData && this.fieldsData.length) {
        groupSelectedFields = this.fieldsData.filter(
          (field) =>
            field.group_key &&
            field.group_key == fields.group_key &&
            data[field.key]
        );
      }
      return groupSelectedFields.length;
    },
    checkRepeatableIsDisabled(field, indexKey) {
      if (
        this.templateRuleFields &&
        this.templateRuleFields.disabledFields &&
        this.templateRuleFields.disabledFields[indexKey]
      ) {
        return this.templateRuleFields.disabledFields[indexKey].find(
          (e) => e == field.key
        )
          ? true
          : false;
      }
      return false;
    },
    checkRepeatableFields(field, indexKey) {
      if (
        this.templateRuleFields &&
        this.templateRuleFields.hideFields &&
        this.templateRuleFields.hideFields[indexKey]
      ) {
        return this.templateRuleFields.hideFields[indexKey].find(
          (e) => e == field.key
        )
          ? true
          : false;
      }
      return false;
    },
    checkFieldFillStatus(field, data) {
      return (
        this.checkRepeatableIsDisabled(field, data.indexKey) ||
        this.checkRepeatableFields(field, data.indexKey)
      );
    },
    checkInBetween(fieldCount, maxValue, minValue) {
      Number.prototype.between = function (a, b) {
        var min = Math.min(a, b),
          max = Math.max(a, b);

        return this >= min && this <= max;
      };
      return fieldCount.between(minValue, maxValue);
    },
    async saveTemplate() {
      this.submitted = true;
      let checkData = this.checkRequiredFields();
      if (checkData.showError) {
        if (checkData.customErrorMessage) {
          this.$notify.error({
            title: "Error",
            message: checkData.customErrorMessage,
          });
        } else if (checkData.emailFormatError) {
          this.$notify.error({
            title: "Error",
            message: "There is an error in the email format. Please check",
          });
        } else if (checkData.checkboxMinMaxCheck) {
          this.$notify.error({
            title: "Error",
            message: `Please select at least '<span style="color: red;">${this.minValue}</span>' option(s) for '<span style="color: red;font-weight:bold">${checkData.checkBoxLabel}</span>' Checkbox group to meet the minimum selection requirement.`,
            dangerouslyUseHTMLString: true,
          });
        } else if (checkData.multiselectMinMaxCheck) {
          this.$notify.error({
            title: "Error",
            message: `Please select at least '<span style="color: red;">${this.minValue}</span>' option(s) for '<span style="color: red;font-weight:bold">${checkData.multiselectLabel}</span>' Multi Select to meet the minimum selection requirement.`,
            dangerouslyUseHTMLString: true,
          });
        } else if (checkData.fileMinMaxCheck) {
          this.$notify.error({
            title: "Error",
            message: `Please upload minimum '${this.fileMinValue}' files or maximum '${this.fileMaxValue}' files , to meet the minimum & maximum file(s) requirement.`,
          });
        }
      } else if (this.isUploading) {
        this.$message.warning("Video is uploading, please wait");
      } else if (
        this.hasRepeatableTemplate &&
        this.entitiesData.length < this.getMinNoOfRecords
      ) {
        let name =
          this.entityRepeatableTemplateData &&
          this.entityRepeatableTemplateData.templateInfo &&
          this.entityRepeatableTemplateData.templateInfo.name
            ? this.entityRepeatableTemplateData.templateInfo.name
            : "template";
        this.$notify.error({
          title: "Error",
          message:
            "Please add " +
            name +
            " data " +
            (this.entitiesData.length > 0
              ? (this.getMinNoOfRecords - this.entitiesData.length || 1) +
                " more times"
              : ""),
        });
      } else {
        // this.checkForDuplicateData();
        this.saveTemplateData();
      }
    },
    async addNewEntityData() {
      try {
        let checked = await this.createContacts();
        if (checked) {
          this.loading = true;
          this.loadingText = "Adding new data...";
          let form = {};
          if (this.hasRepeatableTemplate) {
            let key = "";
            if (
              this.entityRepeatableTemplateData &&
              this.entityRepeatableTemplateData.templateInfo &&
              this.entityRepeatableTemplateData.templateInfo.name
            ) {
              let name = this.entityRepeatableTemplateData.templateInfo.name;
              key = name.toLowerCase().split(" ").join("_");
            } else {
              key = "templateData";
            }
            form[key] = this.entitiesData.map((e) => {
              if (e.isNewData) {
                delete e.isNewData;
              }
              return e;
            });
          } else {
            //  form = this.form;
            let incrementFields = this.fieldsData.filter(
              (e) => e.input_type == "AUTO_INCREMENT_NUMBER"
            );

            if (incrementFields && incrementFields.length) {
              await incrementFields.map(async (field) => {
                this.form[`${field.key}_info`] = {
                  number: field.auto_increment_starting_number,
                  prefix: field.auto_increment_prefix,
                };
              });
            }
            //Update parent entity data by entity variable - mixin method
            if (!this.isApprovalForm) {
              await this.updateParentDataByEntityVariable(
                this.fieldsData,
                this.form
              );
            }
            form = await this.prepareFormData();
            if (
              form &&
              form.first_name &&
              form.last_name &&
              form.first_name.length &&
              form.last_name.length &&
              !form.name
            ) {
              form.name = form.first_name + " " + form.last_name;
            }
          }
          let params = await this.prepareParamData(form);
          await this.$store.dispatch(
            "formbuilderData/createFormbuilderDataByTemplateData",
            params
          );
          if (this.getFormbuilderDataCreateByTemplateStatus) {
            this.dataLoading = false;
            if (this.templateData?.show_success_notification_after_save) {
              this.$notify.success({
                title: "Success",
                message: "Data saved successfully",
              });
            }
            if (
              this.getNewFormbuilderData &&
              this.getNewFormbuilderData.formbuilder_data_status ===
                "COMPLETED" &&
              this.currentStepIndex == this.getAllSteps.length - 1
            ) {
              if (
                this.formbuilderDetails.settings &&
                this.formbuilderDetails.settings.success_and_redirect_page &&
                this.formbuilderDetails.settings.success_and_redirect_page
                  .redirect_to &&
                this.formbuilderDetails.settings.success_and_redirect_page
                  .redirect_to_url
              ) {
                if (
                  this.isValidUrl(
                    this.formbuilderDetails.settings.success_and_redirect_page
                      .redirect_to_url
                  ) &&
                  this.isValidHttpUrl(
                    this.getValidUrl(
                      this.formbuilderDetails.settings.success_and_redirect_page
                        .redirect_to_url
                    )
                  )
                ) {
                  window.location.href = this.getValidUrl(
                    this.formbuilderDetails.settings.success_and_redirect_page
                      .redirect_to_url
                  );
                } else {
                  this.gotoSuccessPage();
                }
              } else {
                this.gotoSuccessPage();
              }
            } else {
              let query = this.getNavigationQuery(this.$route.query);
              query["formbuilder_data_id"] = this.getNewFormbuilderData._id;
              this.$router.push({
                name: this.$route.name,
                query: query,
                params: this.$route.params,
              });
              this.$emit(
                "fetchFormbuilderData",
                this.getNewFormbuilderData._id
              );
              this.gotoNextTemplate(this.currentStepIndex + 1);
            }
          } else {
            this.dataLoading = false;
            let message = "";
            if (this.getNewFormbuilderDataError) {
              message =
                this.getNewFormbuilderDataError.critical_error ||
                this.getNewFormbuilderDataError.message;
            }
            this.$notify.error({
              title: "Error",
              message: message,
            });
          }
        }
      } catch (err) {
        console.log("addNewEntityData", err);
      }
    },
    getValidUrl(url) {
      if (!url.includes("http://") && !url.includes("https://"))
        return "https://" + url;
      return url;
    },
    isValidUrl(url) {
      let re =
        /[-a-zA-Z0-9@:%_+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_+.~#?&//=]*)?/gi;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    },
    isValidHttpUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },
    gotoSuccessPage() {
      let pageName =
        this.$route.name == "CompanyFormbuilderExecuteStep"
          ? "CompanyUserFormbuilderCompleted"
          : this.$route.name == "ApplicationFormbuilderExecuteStep"
          ? "ApplicaionFormbuilderCompleted"
          : "GuestFormbuilderCompleted";
      this.$router.push({
        name: pageName,
        params: {
          formbuilder_code: this.formbuilderDetails.code,
          formbuilder_details_id: this.formbuilderDetails._id,
          formbuilder_data_id: this.getNewFormbuilderData._id,
        },
        query: {
          type: this.$route?.query?.type,
        },
      });
    },
    async prepareParamData(form) {
      let is_approval_form =
        this.isApprovalForm &&
        this.$route.query &&
        this.$route.query.formbuilder_data_id &&
        this.currentFormbuilderData &&
        this.currentFormbuilderData.approval_users &&
        this.getAuthenticatedUser._id == this.currentFormbuilderData.contact &&
        this.currentFormbuilderData.approval_status == "REJECTED"
          ? true
          : false;
      return {
        template_data: form,
        formbuilder_details_id: this.formbuilderDetails._id,
        template_id: this.templateData._id,
        is_repeatable: this.checkIsRepeatable,
        min_records: this.getMinNoOfRecords,
        template_data_id:
          this.existedTemplateDataId && this.existedTemplateDataId._id
            ? this.existedTemplateDataId._id
            : null,
        formbuilder_step_id: this.currentStep._id,
        description_shown: this.description_shown,
        template_completion_status: this.checkIsRepeatable
          ? this.isFormbuilderCompleted
          : this.getRquiredFieldsCount === this.getTotalFieldsCount,
        steps_data: this.getStepsDataForExistedFormbuilderData(),
        contact: this.$route?.query?.user_id || this.getAuthenticatedUser._id,
        schedule_time: this.$route.query?.schedule_time || new Date(),
        time_interval: new Date(),
        formbuilder_data_id:
          this.$route.query && this.$route.query.formbuilder_data_id
            ? this.$route.query.formbuilder_data_id
            : null,
        ...(this.$route.query?.schedule_id && {
          schedule_id: this.$route.query?.schedule_id,
        }),
        standard_template: this.currentStep.standard_template || false,
        created_contacts: this.stepContacts.flatMap((e) => e._id),
        is_approval_form: is_approval_form,
        ...(is_approval_form && {
          approval_users: this.resetApprovers(
            this.currentFormbuilderData.approval_users || []
          ),
        }),
        parent_entityDataId: this.getAuthenticatedUser.account_data_id,
      };
    },
    resetApprovers(users) {
      return users.map((e) => {
        if (e.approved_status) {
          delete e.approved_status;
        }
        if (e.approved_at) {
          delete e.approved_at;
        }
        if (e.approved_by) {
          delete e.approved_by;
        }
        if (e.rejected_status) {
          delete e.rejected_status;
        }
        if (e.approval_status) {
          delete e.approval_status;
        }
        if (e.rejected_at) {
          delete e.rejected_at;
        }
        if (e.rejected_reason) {
          delete e.rejected_reason;
        }
        if (e.rejected_by) {
          delete e.rejected_by;
        }
        return e;
      });
    },
    getStepsDataForExistedFormbuilderData(entityData) {
      if (
        this.currentFormbuilderData &&
        this.currentFormbuilderData.formbuilder_steps_data &&
        this.$route.query &&
        this.$route.query.formbuilder_data_id &&
        this.$route.query.formbuilder_data_id ==
          this.currentFormbuilderData._id &&
        this.currentFormbuilderData.formbuilder_steps_data.length
      ) {
        return this.currentFormbuilderData.formbuilder_steps_data;
      }
      return this.formbuilderDetails.steps.map((e) => {
        if (e.template) {
          e.template_id =
            e.template && e.template._id ? e.template._id : e.template;
        } else if (e.entity) {
          e.entity_id =
            e.entity && e.entity._id
              ? e.entity._id
              : e.entity_id
              ? e.entity_id
              : e.entity;
          if (entityData && entityData.entity_id == e.entity_id) {
            e.entity_data_id = entityData.entityDataId
              ? entityData.entityDataId
              : e?.entityDataId;
          }
        }
        return e;
      });
    },
    async checkAndSetRelationship(entityDataId) {
      if (
        this.$route.query.menu_id &&
        this.getEntityMenu &&
        this.getEntityMenu.menu_list
      ) {
        let currentMenu;
        let id = this.$route.params.menuId || this.$route.query.menu_id;
        this.getEntityMenu.menu_list.forEach((e) => {
          if (e._id == id) {
            currentMenu = e;
          } else if (e.children) {
            (e.children || []).forEach((el) => {
              if (el._id == id) {
                currentMenu = el;
              }
            });
          }
        });
        if (currentMenu?.relational_entity == this.$route.params.entity_id) {
          let relationshipsData = [...(this.getEntityRelationships || [])];

          relationshipsData = relationshipsData.map((e) => {
            if (e.parent_entity_id == this.$route.params.entity_id) {
              e.owner_type = "PARENT";
              e.relationship_title = e.child_relation_title;
              e.relationship_slug = makeSlug(e.relationship_title);
              e.relationship_id = e.child_entity_id;
            } else {
              e.owner_type = "CHILD";
              e.relationship_title = e.parent_relation_title;
              e.relationship_slug = makeSlug(e.relationship_title);
              e.relationship_id = e.parent_entity_id;
            }
            e.parent_entity_data_id = entityDataId
              ? entityDataId
              : this.entityDataId;
            return e;
          });

          relationshipsData = relationshipsData.filter((e) => {
            if (e.owner_type == "CHILD" && !e.is_bidirectional) {
              return false;
            } else {
              return true;
            }
          });
          let currentActiveWorkspace =
            this.getAuthenticatedUser.contact_types.find((e) => {
              let id =
                e.contact_type && e.contact_type._id
                  ? e.contact_type._id
                  : e.contact_type;
              if (id && id == this.getActiveContactType?.contact_type?._id) {
                return true;
              }
            });
          if (
            currentActiveWorkspace &&
            currentActiveWorkspace.contact_type &&
            currentActiveWorkspace.account_data_id
          ) {
            let currentRelationship = relationshipsData.find((e) => {
              let id = currentActiveWorkspace.contact_type._id
                ? currentActiveWorkspace.contact_type._id
                : currentActiveWorkspace.contact_type;
              if (id) {
                if (
                  (id == e.child_entity_id &&
                    e.parent_entity_id == this.$route.params.entity_id) ||
                  (this.$route.params.entity_id == e.child_entity_id &&
                    e.parent_entity_id == id)
                ) {
                  return true;
                }
              }
            });
            if (currentRelationship) {
              if (currentRelationship.representation == "TAB") {
                let data;
                if (currentRelationship.owner_type == "PARENT") {
                  data = {
                    parent_entity_id: currentRelationship.parent_entity_id,
                    child_entity_id: currentRelationship.child_entity_id,
                    parent_entity_data_id:
                      currentRelationship.parent_entity_data_id
                        ? currentRelationship.parent_entity_data_id
                        : entityDataId,
                    child_entity_data_ids: [
                      currentActiveWorkspace.account_data_id,
                    ],
                  };
                } else {
                  if (currentRelationship.parent_relation_type == "MANY") {
                    data = {
                      parent_entity_id: currentRelationship.child_entity_id,
                      child_entity_id: currentRelationship.parent_entity_id,
                      parent_entity_data_id:
                        currentRelationship.parent_entity_data_id
                          ? currentRelationship.parent_entity_data_id
                          : entityDataId,
                      child_entity_data_ids: [
                        currentActiveWorkspace.account_data_id,
                      ],
                    };
                  } else {
                    data = {
                      parent_entity_id: currentRelationship.parent_entity_id,
                      child_entity_id: currentRelationship.child_entity_id,
                      parent_entity_data_id:
                        currentActiveWorkspace.account_data_id,
                      child_entity_data_ids: [
                        currentRelationship.parent_entity_data_id
                          ? currentRelationship.parent_entity_data_id
                          : entityDataId,
                      ],
                    };
                  }
                }
                await this.$store.dispatch(
                  "entityRelationships/assignEntityRelationshipData",
                  data
                );
              } else {
                let allFields = this.getAllFieldsFormEntity(
                  this.getEntityDataById
                );
                let toAssignRelationshipsData = this.prepareRelationshipData(
                  allFields,
                  relationshipsData,
                  entityDataId ? entityDataId : this.entityDataId,
                  currentActiveWorkspace &&
                    currentActiveWorkspace.account_data_id
                    ? currentActiveWorkspace.account_data_id
                    : null
                );
                if (toAssignRelationshipsData.length) {
                  await this.$store.dispatch(
                    "entityRelationships/assignFieldsEntityRelationshipData",
                    toAssignRelationshipsData
                  );
                }
              }
            }
          }
        }
      }
    },
    getAllFieldsFormEntity(entity) {
      if (entity && entity.templates && entity.templates.length) {
        let result = [];
        entity.templates.forEach((e) => {
          if (e && e.templateInfo) {
            result = [...result, ...this.getTemplateFields(e.templateInfo)];
          }
        });
        return result;
      }
      return [];
    },
    getTemplateFields(templateData) {
      let fields = [];
      let data = JSON.parse(JSON.stringify(templateData));
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });
      fields = fields.map((e) => {
        e.template_id = data._id;
        return e;
      });
      return fields;
    },
    async updateTemplateData() {
      try {
        let checked = await this.createContacts();
        if (checked) {
          let form = {};
          if (this.hasRepeatableTemplate) {
            let key = "";
            if (
              this.entityRepeatableTemplateData &&
              this.entityRepeatableTemplateData.templateInfo &&
              this.entityRepeatableTemplateData.templateInfo.name
            ) {
              let name = this.entityRepeatableTemplateData.templateInfo.name;
              key = name.toLowerCase().split(" ").join("_");
            } else {
              key = "templateData";
            }
            const promises = await this.entitiesData.map(async (e) => {
              //Update parent entity data by entity variable - mixin method
              if (!this.isApprovalForm) {
                await this.updateParentDataByEntityVariable(this.fieldsData, e);
              }
              for (let [key, value] of Object.entries(e)) {
                //Update parent entity data by entity variable - mixin method
                if (!this.isApprovalForm) {
                  await this.updateParentDataByEntityVariable(
                    this.fieldsData,
                    e
                  );
                }
                if (
                  value &&
                  typeof value == "object" &&
                  value.type == "DOCUMENT" &&
                  value.new == true &&
                  value.file
                ) {
                  this.loadingText = "Uploading files...";
                  let path = await this.updatedAndUploadDocument(value.file);
                  this.$set(e[key], "path", path);
                  this.$set(e[key], "new", false);
                  //  this.form[key].path = path;
                  //  this.form[key].new = false;
                }
              }
              if (e.isNewData) {
                delete e.isNewData;
              }

              return e;
            });
            form[key] = await Promise.all(promises);
          } else {
            let incrementFields = this.fieldsData.filter(
              (e) => e.input_type == "AUTO_INCREMENT_NUMBER"
            );

            if (incrementFields && incrementFields.length) {
              await incrementFields.map(async (field) => {
                this.form[`${field.key}_info`] = {
                  number: field.auto_increment_starting_number,
                  prefix: field.auto_increment_prefix,
                };
              });
            }
            //Update parent entity data by entity variable - mixin method
            if (!this.isApprovalForm) {
              await this.updateParentDataByEntityVariable(
                this.fieldsData,
                this.form
              );
            }
            form = await this.prepareFormData();
            if (
              this.form &&
              this.form.first_name &&
              this.form.last_name &&
              this.form.first_name.length &&
              this.form.last_name.length &&
              !this.form.name
            ) {
              this.form.name = this.form.first_name + " " + this.form.last_name;
            }
          }
          let params = {
            template_data: form,
            entity_data_id: this.formbuilderDataId,
            template_id: this.templateData._id,
            entity_id: this.getEntityId,
          };
          this.loading = true;
          await this.$store.dispatch(
            "templatesData/updateUserTemplateData",
            params
          );
          this.loading = false;
          if (this.getTemplatesDataUpdateStatus) {
            if (this.templateData?.show_success_notification_after_save) {
              this.$notify.success({
                title: "Success",
                message: "Data updated successfully",
              });
            }
            bus.$emit("fetch-entity-data");
            await this.updateEntitiesData();
            this.gotoNextTemplate();
          } else {
            let msg = "Error while updating";
            if (this.getUserTemplateDataUpdateErrors?.message) {
              msg = this.getUserTemplateDataUpdateErrors.message;
            }
            this.$notify.error({
              title: "Error",
              message: msg,
            });
          }
        }
      } catch (err) {
        console.log("updateTemplateData", err);

        this.$notify.error({
          title: "Error",
          message: "Error at updating data",
        });
      }
    },
    async createContacts() {
      if (
        this.hasRepeatableTemplate &&
        this.currentStep.standard_template &&
        this.currentStep?.settings?.contact_entity
      ) {
        let data = [],
          sameMail = "",
          mails = [];
        (this.entitiesData || []).forEach((form) => {
          if (mails.indexOf(form.email.toLowerCase()) !== -1) {
            sameMail = form.email.toLowerCase();
          }
          let existedContact = form;
          data.push({
            ...form,
            ...{
              email: form.email.toLowerCase(),
              name: form.first_name
                ? form.first_name + " " + (form.last_name ? form.last_name : "")
                : "",
              company_id: this.formbuilderDetails.company,
              created_by: this.formbuilderDetails.created_by,
              user_id: this.formbuilderDetails.created_by,
              contact_type: this.currentStep.settings.contact_entity,
              entity_id: this.currentStep.settings.contact_entity,
            },
            ...(this.currentStep?.settings?.contact_entity_type ==
              "BUSINESS" && {
              account_data_id: this.getAuthenticatedUser.account_data_id,
            }),
            ...(existedContact &&
              existedContact._id && {
                contact_id: existedContact._id,
              }),
          });
          mails.push(form.email.toLowerCase());
        });
        if (sameMail) {
          this.$message({
            message: sameMail + " is taken multiple times",
            type: "warning",
          });
          return;
        }
        await this.$store.dispatch("contacts/createContactsForFormbuilder", {
          contacts: data,
          company_id: this.formbuilderDetails.company,
          created_by: this.formbuilderDetails.created_by,
          user_id: this.formbuilderDetails.created_by,
          contact_type: this.currentStep.settings.contact_entity,
          entity_id: this.currentStep.settings.contact_entity,
        });
        if (this.getCreateContactsForFormbuilderData) {
          let successCount = 0,
            failedCount = 0,
            message = "Error while checking data";
          (this.getCreateContactsForFormbuilderData || []).forEach((e) => {
            if (e.success && e?.contactData?._id) {
              this.stepContacts.push(e.contactData);
              successCount++;
            } else {
              failedCount++;
              if (e?.message) {
                message = e.message;
              }
            }
          });
          if (successCount == 0) {
            this.$message({
              message: message,
              type: "error",
            });
            return false;
          } else if (successCount > 0 && failedCount == 0) {
            this.$message({
              message: "Contacts created successfully",
              type: "success",
            });
            return true;
          } else if (successCount > 0 && failedCount > 0) {
            this.$message({
              message:
                "Contacts created successfully and some contacts already existed",
              type: "success",
            });
            return false;
          }
        }
      }
      return true;
    },
    async saveTemplateData() {
      if (this.isEdit && this.formbuilderDataId) {
        this.updateTemplateData();
      } else {
        this.addNewEntityData();
      }
    },
    async updateEntitiesData(entityDataId) {
      // ** mixin method ** //
      let toAssignRelationshipsData = this.prepareRelationshipData(
        this.fieldsData,
        this.getCurrentEntityRelationships,
        entityDataId ? entityDataId : this.entityDataId
      );

      if (toAssignRelationshipsData.length) {
        await this.$store.dispatch(
          "entityRelationships/assignFieldsEntityRelationshipData",
          toAssignRelationshipsData
        );
      }
    },
    handleFileUploadData(data) {
      let suggestedDataFields = ["ENTITY", "DATA_TABLE", "ENTITY_TABLE"];
      Object.keys(data).map((e) => {
        let field = this.fieldsObject[e];
        if (field && !suggestedDataFields.includes(field?.input_type)) {
          this.$set(this.form, e, data[e]);
        }
      });
      this.suggestedData = {
        ...data,
      };
    },
    async prepareFormData() {
      for (let [key, value] of Object.entries(this.filesInfo)) {
        if (
          value &&
          typeof value == "object" &&
          value.type == "DOCUMENT" &&
          value.new == true &&
          value.files
        ) {
          this.loading = true;
          this.loadingText = "Uploading files...";
          let path = await this.uploadFile(value.files);
          this.filesInfo[key].path = path;
          this.filesInfo[key].new = false;
          this.form[key] = this.filesInfo[key];
        }
      }
      return this.form;
    },
    async uploadFile(file) {
      var formData = new FormData();
      file.forEach((el) => {
        formData.append("files", el.raw !== undefined ? el.raw : el);
      });
      formData.append("path", "template-data-documents");
      let filesData = await axios.post(`/presignMultiUpload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(filesData);
    },
    async updatedAndUploadDocument(file) {
      let params = {
        data1: {
          file_name: file.raw.name,
          file_type: file.raw.type,
          file_size_in_kb: file.raw.size / 1000,
        },
        data2: file.raw,
      };
      await this.$store.dispatch(
        "templatesData/uploadDocumentAndGetUrl",
        params
      );
      if (this.getUploadAndUpdateDocument) {
        return this.getUploadAndUpdateDocument;
      } else {
        return "";
      }
    },
    async getFileUploadUrl(file) {
      let data = {
        file_name: file.raw.name,
        file_type: file.raw.type,
        file_size_in_kb: file.raw.size / 1000,
      };
      await this.$store.dispatch("templatesData/fetchDocumentUploadUrl", data);
    },
    async checkDataTableDuplicateData(fieldsData, form) {
      let result = {
        success: false,
        message: "Error while checking data",
      };
      let duplicateDataTable = fieldsData.find((field) => {
        if (
          field.inputType == "DATA_TABLE" &&
          field.data_table_columns &&
          field.all_duplicate_rules &&
          field.all_duplicate_rules.length
        ) {
          let results = [];
          (field.all_duplicate_rules || []).forEach((el) => {
            results.push(this.checkSingleDuplicate(el, form[field.key]));
          });
          if (
            field.duplicate_query_type == "$and" &&
            results.every((el) => el)
          ) {
            return true;
          } else if (
            field.duplicate_query_type == "$or" &&
            results.some((el) => el)
          ) {
            return true;
          }
        }
      });
      if (duplicateDataTable?.label) {
        result.message =
          duplicateDataTable.label +
          " has duplicate data. Please add it in one row.";
      } else {
        result.success = true;
      }
      return result;
    },
    checkCombinationDuplicates(objArray, propertyNames) {
      const combinations = objArray.map((obj) =>
        propertyNames.map((prop) => obj[prop]).join("-")
      );
      const duplicateCombinations = new Set(
        combinations.filter(
          (combination, index, arr) => arr.indexOf(combination) !== index
        )
      );

      return duplicateCombinations.size > 0;
    },
    checkSingleDuplicate(rules, data) {
      if (data && data.length > 1) {
        let results = [];
        (rules || []).forEach((e) => {
          if (e?.combination_type == "ALL") {
            let counts = this.checkCombinationDuplicates(data, [
              e.selected_field,
            ]);
            results.push(counts);
          } else {
            results.push(false);
          }
        });
        return results.every((el) => el);
      }
      return false;
    },
    async checkForDuplicateData() {
      if (this.hasRepeatableTemplate) {
        this.saveTemplateData();
        return;
      }
      let checkData = await this.checkDataTableDuplicateData(
        this.fieldsData,
        this.form
      );
      if (checkData?.success) {
        this.loading = true;
        this.loadingText = "Checking duplicate data...";
        await this.$store.dispatch("templatesData/checkDuplicateData", {
          data: [
            {
              form: this.form,
              template_id: this.templateData._id,
            },
          ],
          entity_id: this.formbuilderId,
          parent_entityDataId: this.formbuilderDataId || null,
        });
        if (this.getDuplicateDataCheck) {
          if (!this.getDuplicateDataCheck?.allowed) {
            this.$notify.warning({
              title: "Warning",
              message:
                "With this data already " +
                (this.getEntityDataById && this.getEntityDataById.name
                  ? this.getEntityDataById.name + " is"
                  : "data is") +
                " created.",
            });
            this.loading = false;
            return;
          }
          this.saveTemplateData();
        } else {
          this.loading = false;
          this.$message({
            message: "Error while checking data",
            type: "error",
          });
        }
      } else {
        this.$message({
          message: checkData.message || "Error while checking data",
          type: "error",
        });
      }
    },
    gotoNextTemplate(step) {
      this.$emit("goto-next", step);
    },
    existedTemplateDataId() {
      return this.entityData?.templates_data.find(
        (e) => e.template_id == this.templateData._id
      );
    },
    setTemplateData() {
      let activeTemplateData = (
        this.currentFormbuilderData?.formbuilder_steps_data || []
      ).find((e) => e.template_id == this.templateData._id);
      this.form = {};
      if (activeTemplateData?.template_data_id?.template_data) {
        this.setFormData(activeTemplateData.template_data_id.template_data);
      } else if (activeTemplateData?.created_contacts) {
        this.entitiesData = [...activeTemplateData.created_contacts];
      }
    },
    setFormData(data) {
      this.showData = false;
      if (this.hasRepeatableTemplate) {
        if (Object.keys(data)) {
          let key = "";
          if (
            this.entityRepeatableTemplateData &&
            this.entityRepeatableTemplateData.templateInfo &&
            this.entityRepeatableTemplateData.templateInfo.name
          ) {
            let name = this.entityRepeatableTemplateData.templateInfo.name;
            key = name.toLowerCase().split(" ").join("_");
          } else {
            key = "templateData";
          }
          if (Object.keys(data).length == 1 && data[Object.keys(data)[0]]) {
            this.entitiesData = data[Object.keys(data)[0]];
          } else if (key && data[key]) {
            this.entitiesData = data[key];
          } else {
            this.entitiesData.push(data);
          }
        }
      } else {
        this.existedTemplateDataValues = data;
        this.fieldsData.forEach((el) => {
          let field = JSON.parse(JSON.stringify(el));
          if (field?.key && field.key.includes("#")) {
            field.key = field.key.split("#")[1];
          }
          if (field.inputType === "AUTO_INCREMENT_NUMBER") {
            this.$set(
              this.form,
              field.key + "_prefix",
              data && data[field.key + "_prefix"]
                ? data[field.key + "_prefix"]
                : {}
            );
          }

          if (field.inputType === "PHONE_COUNTRY_CODE") {
            this.$set(
              this.form,
              field.key + "_code",
              data && data[field.key + "_code"] ? data[field.key + "_code"] : {}
            );
          }
          if (field.inputType === "CURRENCY") {
            this.$set(
              this.form,
              field.key + "_currency",
              field.validations.currency
            );
            this.$set(this.form, field.key, data[field.key]);
          }
          if (field.inputType === "NUMBER") {
            this.$set(this.form, field.key, data[field.key]);
          }
          if (
            field.inputType === "SINGLE_LINE_TEXT" &&
            field.input_type === "MASKED"
          ) {
            this.$set(
              this.form,
              field.key + "_masked",
              data && data[field.key + "_masked"]
                ? data[field.key + "_masked"]
                : ""
            );
          }
          // if (
          //   field.inputType === "SINGLE_LINE_TEXT" &&
          //   field.input_type === "HYPER_LINK"
          // ) {
          //   this.$set(
          //     this.form,
          //     field.key ,
          //     data && data[field.key ]
          //       ? data[field.key]
          //       : ""
          //   );
          // }
          if (field.input_type === "VIDEO") {
            this.$set(
              this.form,
              field.key + "_video",
              data && data[field.key + "_video"]
            );
          }
          if (field.input_type == "QUESTION" && field.has_other_option) {
            this.$set(
              this.form,
              `${field.key}/otheroption`,
              data[field.key + "/otheroption"]
                ? data[field.key + "/otheroption"]
                : ""
            );
          }
          if (field.input_type == "CHECKBOX_GROUP") {
            this.$set(
              this.form,
              field.key,
              data[field.key] ? data[field.key] : ""
            );
          }
          if (field.input_type != "CHECKBOX_GROUP" && data && data[field.key]) {
            this.$set(this.form, field.key, data[field.key]);
          }
          if (data && data[field.key + "/name"]) {
            this.$set(
              this.form,
              field.key + "/name",
              data[field.key + "/name"]
            );
          }
        });
      }
      setTimeout(() => {
        this.showData = true;
      }, 500);
    },
    async setTemplateInfo(templateData) {
      this.loading = true;
      this.interactiveQuestionsMode =
        templateData && templateData.use_interactive_questionare;
      this.interactiveQuestionsModeType =
        templateData && templateData.interactive_questionare_type;
      if (this.templateData?.sections[0]?.fields) {
        let defaultSection =
          templateData &&
          templateData.sections.find((x) => x.type == "DEFAULT");
        defaultSection.fields = defaultSection.fields.sort((a, b) => {
          return a.y - b.y;
        });
        this.fieldsData = this.setSizeAndPositionForElements(
          defaultSection.fields
        );
        await this.checkRepeatableTemplate();
        this.setTemplateData();
        let lastElement = defaultSection.fields[this.fieldsData.length - 1];
        this.formHeight = lastElement.y + lastElement.height;
      }
      if (
        templateData &&
        templateData.sections &&
        templateData.sections[0] &&
        templateData.sections[0].checkBoxGroup
      ) {
        this.singleCompanyTemplateCheckBoxArray =
          templateData.sections[0].checkBoxGroup;
      }
      this.templateStyles =
        templateData && templateData.styles ? templateData.styles : {};
      this.templateRules = templateData.rules ? [...templateData.rules] : [];
      this.applyRulesOnFields(this.fieldsData, this.templateRules, this.form);
      this.authenticatedUserInfoFromFormbuilder =
        this.getAuthenticatedUser || {};
      this.loading = false;
    },
    calculateRelativeLeft(x) {
      return x;
    },

    calculateRelativeTop(y) {
      return y;
    },
    calculateRelativeWidth(width) {
      // if (
      //   this.storedFormBuilderWidth &&
      //   this.currentFormBuilderWidth &&
      //   this.currentFormBuilderWidth != this.storedFormBuilderWidth
      // ) {
      //   return Math.round(
      //     (this.currentFormBuilderWidth / this.storedFormBuilderWidth) * width
      //   );
      // }
      return width;
    },
    setSizeAndPositionForElements(elements) {
      let questionIndex = 1;
      return elements.map((field) => {
        field = {
          ...field,
        };
        field.x = this.calculateRelativeLeft(field.x);
        field.y = this.calculateRelativeTop(field.y);

        let width = this.calculateRelativeWidth(field.width);
        let height = this.calculateRelativeWidth(field.height);
        field.width = width;
        field.height = height;
        if (field.type == "FIELD") {
          field.questionIndex = questionIndex;
          questionIndex = questionIndex + 1;
        }
        if (this.getEntityId) {
          field.parent_entityId = this.getEntityId;
          field.parent_entityDataId = this.entityDataId;
        }
        if (field.input_type === "DATA_TABLE") {
          field.data_table_columns = (field.data_table_columns || []).map(
            (e) => {
              e.parent_entityId = this.getEntityId;
              e.parent_entityDataId = this.entityDataId;
              if (this.templateData?._id) {
                e.template_id = this.templateData._id;
              }
              return e;
            }
          );
        }
        if (this.templateData?._id && !field.template_id) {
          field.template_id = this.templateData._id;
        }
        return field;
      });
    },
    async checkRepeatableTemplate() {
      if (this.currentStep?.settings?.is_repeatable) {
        this.entityRepeatableTemplateData = {
          senderAlias: this.currentStep?.settings?.sender_title,
          default_display: "TABLE",
          receiverAlias: this.currentStep?.settings?.receiver_title,
          max_records: this.currentStep?.settings?.max_records,
          templateInfo: this.currentStep?.template,
          max_linked_field: this.currentStep?.settings?.max_linked_field,
          min_linked_field: this.currentStep?.settings?.min_linked_field,
          min_records: this.currentStep?.settings?.min_records,
        };
        let fields = [];
        if (this.currentStep?.template?.sections) {
          await this.currentStep?.template.sections.forEach((sec) => {
            fields = [...fields, ...sec.fields];
          });
        }
        await fields.forEach(async (e) => {
          await this.$set(this.form, e.key, "");
        });
        this.hasRepeatableTemplate = true;
        if (!this.entitiesData.length) {
          this.addNewRepeatableData();
        }
      } else if (this.getEntityDataById && this.getEntityDataById.templates) {
        this.entityRepeatableTemplateData =
          this.getEntityDataById.templates.find(
            (e) => this.templateData && e.template_id == this.templateData._id
          );
        if (
          this.entityRepeatableTemplateData &&
          this.entityRepeatableTemplateData.is_repeatable
        ) {
          let fields = [];
          if (
            this.entityRepeatableTemplateData &&
            this.entityRepeatableTemplateData.templateInfo
          ) {
            await this.entityRepeatableTemplateData.templateInfo.sections.forEach(
              (sec) => {
                fields = [...fields, ...sec.fields];
              }
            );
          }
          await fields.forEach(async (e) => {
            await this.$set(this.form, e.key, "");
          });
          this.hasRepeatableTemplate =
            this.entityRepeatableTemplateData.is_repeatable;
          if (this.hasRepeatableTemplate) {
            this.dependentFieldsInfo();
          }
        }
      }
    },
    dependentFieldsInfo() {
      let templateIds = [];
      if (
        this.entityRepeatableTemplateData.min_linked_field &&
        this.entityRepeatableTemplateData.min_linked_field.split("#")[1]
      ) {
        templateIds.push({
          template_id:
            this.entityRepeatableTemplateData.min_linked_field.split("#")[1],
          field:
            this.entityRepeatableTemplateData.min_linked_field.split("#")[2],
          is_max: false,
        });
      }
      if (
        this.entityRepeatableTemplateData.max_linked_field &&
        this.entityRepeatableTemplateData.max_linked_field.split("#")[1]
      ) {
        templateIds.push({
          template_id:
            this.entityRepeatableTemplateData.max_linked_field.split("#")[1],
          field:
            this.entityRepeatableTemplateData.max_linked_field.split("#")[2],
          is_max: true,
        });
      }
      if (
        this.entityRepeatableTemplateData.max_records &&
        !this.entityRepeatableTemplateData?.max_linked_field
      ) {
        this.max_value = this.entityRepeatableTemplateData.max_records;
      }
      if (
        this.entityRepeatableTemplateData.min_records &&
        !this.entityRepeatableTemplateData?.min_linked_field
      ) {
        this.min_value = this.entityRepeatableTemplateData.min_records;
      }
      if (this.max_value < this.min_value) {
        this.max_value = this.min_value;
      }
      this.fetchDependentData(templateIds);
    },
    async fetchDependentData(templateIds) {
      if (templateIds.length) {
        await this.$store.dispatch("templatesData/fetchRequiredTemplateData", {
          entity_data_ids: [this.entityDataId],
          template_ids: templateIds.flatMap((e) => e.template_id),
        });
        if (
          this.getRequiredTemplatesData &&
          this.getRequiredTemplatesData.length
        ) {
          templateIds.forEach((e) => {
            let seletedTemplateData = this.getRequiredTemplatesData.find(
              (te) => te.template_id == e.template_id
            );
            if (
              seletedTemplateData?.template_data &&
              seletedTemplateData?.template_data[e.field]
            ) {
              if (e.is_max) {
                this.max_value = parseInt(
                  seletedTemplateData?.template_data[e.field]
                );
              } else {
                this.min_value = parseInt(
                  seletedTemplateData?.template_data[e.field]
                );
              }
            }
            if (this.max_value < this.min_value) {
              this.max_value = this.min_value;
            }
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bottm-bar {
  padding-top: 10px;
  border-top: 1px solid #e6e6e6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .progress {
    width: 30%;
  }
}

.preview-template-view {
  .header-action-block {
    margin-right: 10px;
  }

  .template-body-section {
    .el-scrollbar {
      border: 1px solid #e4e7ec;
    }

    margin-top: 10px;
    width: 100%;
    // @media (max-width: 766.98px) {
    //   width: 70%;
    // }
  }
}

.template-body-section {
  .form-builder {
    .form-fields-holder {
      @media (max-width: 991.98px) {
        & > div {
          position: relative !important;
          left: 10px !important;
          top: 0px !important;
          margin-top: 30px;
          width: calc(100% - 20px) !important;

          input {
            width: 100% !important;
          }

          .heading {
            margin-top: 40px !important;
          }

          p {
            width: 100% !important;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.custom-tooltip {
  position: absolute;
  background-color: red;
  color: rgb(0, 0, 0);
  padding: 5px;
  border-radius: 5px;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}
.sub-btn {
  border-radius: 2px;
  padding: 0.45em 0.65em !important;
  border: 1px solid #285ed3;
  background: transparent;
  color: #285ed3 !important;
  margin-right: 10px !important;

  &:hover {
    color: white !important;
    background: #285ed3;
    border: 1px solid #285ed3 !important;
  }
}

.edit-btn {
  border-radius: 2px !important;
  padding: 0.45em 0.65em;
  border: 1px solid #f68c8c;
  background: transparent;
  color: #f68c8c !important;

  &:hover {
    color: white !important;
    background: #f68c8c !important;
    border: 1px solid #f68c8c !important;
  }
}

.preview-template-view {
  margin: 0.5em 0em;

  h1 {
    margin-bottom: 25px !important;
    font-size: 1.125em;
    font-weight: 500;
  }

  .form-group {
    margin-bottom: 25px;
  }

  .preview-form-header {
    margin-top: 1em;
    margin-left: 10px;
    z-index: 100;

    h1 {
      font-size: 2.1em;
      font-family: "Mulish", sans-serif;
      font-weight: 500;
      letter-spacing: -0.5px;
      color: #1f2f3d;
      margin-bottom: 0.2em !important;
    }

    p {
      margin-top: 0;
      padding: 0.15em 0 1.5em;
      line-height: 1.5 !important;
      color: #5e6d82;
    }
  }

  .header-action-block {
    margin-right: 10px !important;

    .default-button {
      display: block;
      text-transform: uppercase;
      color: #5e6d82;
      min-width: 100px;
      margin: 0.5em 0 0 auto;
    }
  }

  .footer-action-block {
    //position: relative !important;
    margin-bottom: 3em;

    .el-button {
      margin-top: -39px;
      display: block;
      padding: 0.7em 1em;
      margin-right: 20px;
      min-width: 20px;
      font-size: 1em;
    }

    .required-fields-info {
      top: 10px;
      font-size: 14px;

      & > div {
        margin-right: 1.5em;
        border: 1px solid #eeeeee;
        padding: 0.65em 1em;
        border-radius: 4px;
        font-size: 1em;
        letter-spacing: 0.5px;
      }
    }
  }

  .sty {
    margin-top: 500px;
  }

  .form-builder {
    position: relative;

    @media (max-width: 991.98px) {
      width: 100%;
    }

    width: 100% !important;

    .form-fields-holder {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      .each-element img {
        max-width: 100%;
      }
    }
  }
}

.el-button--success {
  margin-bottom: 0.5em;
}
</style>
<style scoped>
.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}
</style>
